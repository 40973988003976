import '../../style/animation.css';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { t } from 'i18next';
import { Button } from 'antd';
import CommButton from '../common/Button';
import useRobotstore from '../../store/robotStore';
import useCommonstore from '../../store/modalStore';
import useModalstore from '../../store/modalStore';
import {
  CurrentTask,
  DeviceDataType,
  MultiRobotCommandType,
  TaskRobotType,
} from '../../_types';
import DetailError from '../error/DetailError';
import useUserData from '../../hooks/useUserData';
import useRobotData from '../../hooks/useRobotData';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getCommandList, postSiteCommand } from '../../_api/rest/robot';
import { stopWorkflowApi } from '../../_api/rest/workflow';
import { deleteTask, postStopTask } from '../../_api/rest/schedule';
import TaskCard from '../schedule/common/TaskCard';

export default function MultiRobotDetail() {
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { openModal } = useCommonstore();

  const {
    isShowMultiRobotDetail,
    selectedTaskDetailData,
    selectedTask,
    updateIsShowMultiRobotDetail,
  } = useRobotstore();
  const { closeModal } = useModalstore();
  const { user } = useUserData();
  const { syncRobotList } = useRobotData(user);

  const [multiRobotData, setMultiRobotData] = useState<
    DeviceDataType[] | null
  >();
  const [commandList, setCommandList] = useState<MultiRobotCommandType[]>([]);

  useEffect(() => {
    if (!selectedTask) return;

    setMultiRobotData(
      syncRobotList?.filter(({ deviceId }) =>
        selectedTaskDetailData?.robotList
          ?.map((robot: TaskRobotType) => robot.deviceId)
          .includes(deviceId),
      ),
    );
  }, [syncRobotList, selectedTask]);

  useEffect(() => {
    (async () => {
      if (!selectedTaskDetailData?.siteId) return;

      const commandList = await getCommandList(selectedTaskDetailData?.siteId);
      setCommandList(commandList);
    })();
  }, [selectedTaskDetailData]);

  const matchStateColor = (robotState: string) => {
    switch (robotState) {
      case 'RED':
        return 'bg-red-500';
      case 'GREEN':
        return 'bg-emerald-500';
      case 'ORANGE':
        return 'bg-orange-400';
      case 'GRAY':
        return 'bg-gray-400';
      default:
        return 'bg-gray-400';
    }
  };

  const closeMenu = () => {
    updateIsShowMultiRobotDetail(false);
  };

  const handleExecuteTask = async (command: string) => {
    const params = {
      siteId: selectedTaskDetailData?.siteId,
      taskId: Number(selectedTaskDetailData?.taskId),
      command: 'COMMAND_TASK',
    };

    const res = await postSiteCommand(params);
    res?.result === 'SUCCESS'
      ? toast.success('태스크를 실행했습니다.')
      : toast.error('태스크 실행에 실패했습니다.');
  };

  const handleStopTask = async () => {
    const params = {
      siteId: selectedTaskDetailData?.siteId,
      taskId: selectedTaskDetailData?.currentTask?.taskId,
      activation: 'STOP',
    };

    const res = await stopWorkflowApi(params);
    res?.result === 'SUCCESS'
      ? toast.success('태스크를 중지했습니다.')
      : toast.error('태스크 중지에 실패했습니다.');
  };

  const style = !selectedTask
    ? 'w-[0px]'
    : `z-[100] fixed right-0 w-[650px] h-[100vh] p-6 px-7 bg-white flex flex-col drop-shadow overflow-scroll ${
        isShowMultiRobotDetail ? 'robot-menu-on' : 'robot-menu-off'
      }`;

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    command: string,
  ) => {
    event.preventDefault();

    const userName = userNameRef.current?.value;
    const password = passwordRef.current?.value;

    if (!userName || !password) {
      return toast.error('이름과 비밀번호를 입력해주세요.');
    }

    const params = {
      siteId: selectedTaskDetailData?.siteId,
      taskId: selectedTaskDetailData?.taskId,
      command,
      param: {
        userName,
        password,
      },
    };

    const res = await postSiteCommand(params);

    if (res.result === 'SUCCESS') {
      toast.success('정보를 입력했습니다.');
      closeModal();
    } else {
      toast.error('정보 입력에 실패했습니다.');
    }
  };

  const handleInputInfo = (command: string) => {
    openModal({
      type: 'info',
      title: '정보 입력',
      contents: (
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event, command)
          }
          className="flex flex-col items-center justify-center w-full py-5 mt-3 space-y-4 text-sm px-14"
        >
          <div className="flex flex-row items-center w-full">
            <label
              htmlFor="patientName"
              className="flex-shrink-0 w-1/4 text-left text-gray-700"
            >
              환자명
            </label>
            <input
              type="text"
              id="patientName"
              ref={userNameRef}
              className="flex-grow p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-row items-center w-full">
            <label
              htmlFor="password"
              className="flex-shrink-0 w-1/4 text-left text-gray-700"
            >
              비밀번호
            </label>
            <input
              type="password"
              id="password"
              ref={passwordRef}
              className="flex-grow p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex px-12 py-2 justify-center gap-[30px] w-full min-w-[300px]">
            <CommButton
              label={`${t('confirm')}`}
              circle
              full
              type="submit"
              size="small"
              primary
              onClick={(event: React.FormEvent<HTMLFormElement>) =>
                handleSubmit(event, command)
              }
            />
            <CommButton
              label={`${t('cancel')}`}
              full
              circle
              type="button"
              size="small"
              onClick={closeModal}
            />
          </div>
        </form>
      ),
    });
  };

  return (
    <div className={style} data-name="detail" data-name2="aa">
      {selectedTaskDetailData ? (
        <ErrorBoundary FallbackComponent={DetailError}>
          <div
            onClick={closeMenu}
            className="self-end text-black hover:cursor-pointer"
          >
            ✕
          </div>
          <div className="pb-3">
            <span className="text-2xl">{selectedTaskDetailData?.taskName}</span>
            <span className="text-xl">
              : {selectedTaskDetailData?.description}
            </span>
          </div>

          {selectedTaskDetailData?.taskMainState !== 'TASK_POSSIBLE' &&
            selectedTaskDetailData?.taskMainState !== 'TASK_IMPOSSIBLE' && (
              <TaskCard
                taskId={selectedTaskDetailData?.currentTask?.taskId}
                taskName={selectedTaskDetailData?.currentTask?.taskName}
                taskState={selectedTaskDetailData?.currentTask?.taskState}
                currentAction={
                  selectedTaskDetailData?.currentTask?.currentAction
                }
                prevAction={selectedTaskDetailData?.currentTask?.prevAction}
                agentNameList={
                  selectedTaskDetailData?.currentTask?.agentNameList
                }
                currentActionAlias={
                  selectedTaskDetailData?.currentTask?.currentActionAlias
                }
                prevActionAlias={
                  selectedTaskDetailData?.currentTask?.prevActionAlias
                }
                currentActionCount={
                  selectedTaskDetailData?.currentTask?.currentActionCount
                }
                totalActionCount={
                  selectedTaskDetailData?.currentTask?.totalActionCount
                }
                startTime={selectedTaskDetailData?.currentTask?.startTime}
                lastUpdateTime={
                  selectedTaskDetailData?.currentTask?.lastUpdateTime
                }
                ellapsedTimeTotal={
                  selectedTaskDetailData?.currentTask?.ellapsedTimeTotal
                }
                isStopped={
                  selectedTaskDetailData?.currentTask?.taskState === 'USER_STOP'
                }
                isHideButton={true}
                onStopClick={() =>
                  postStopTask({
                    siteId: selectedTaskDetailData?.currentTask?.siteId,
                    taskId: selectedTaskDetailData?.currentTask?.taskId,
                    activation: 'STOP',
                  })
                    .then(() => {
                      toast.success(t('It_has_been_stopped'));
                      closeModal();
                    })
                    .catch((error) => toast.error(`${error}`))
                }
                onDeleteClick={() =>
                  deleteTask({
                    siteId: selectedTaskDetailData?.currentTask?.siteId,
                    taskId: selectedTaskDetailData?.currentTask?.taskId,
                  })
                    .then(() => {
                      toast.success(t('It_has_been_deleted'));
                      closeModal();
                    })
                    .catch((error) => toast.error(`${error}`))
                }
              />
            )}

          <div className="mt-6 mb-7">
            <div className="mb-2 text-sm font-bold">{t('투입 로봇 정보')}</div>
            <div className="w-full text-xs text-center">
              <div className="flex items-center font-bold rounded-lg shadow-sm bg-neutral-200">
                <div className="p-2 w-[15%]">{t('Device_Name')}</div>
                <div className="p-2 w-[23%]">{t('Model_Name')}</div>
                <div className="p-2 w-[22%]">{t('Current_Status')}</div>
                <div className="p-2 w-[7%]">{t('status')}</div>
                <div className="p-2 w-[13%]">{t('battery_level')}</div>
                <div className="p-2 w-[20%]">{t('Data_Update_Time')}</div>
              </div>

              <div>
                {multiRobotData?.map((item: any, idx: number) => (
                  <div
                    key={idx}
                    data-name="list"
                    className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] border border-gray-300 ${
                      localStorage.login === 'true' &&
                      localStorage.verified === 'true'
                        ? 'justify-center mt-1 rounded-xl shadow-sm'
                        : 'blur-sm'
                    }`}
                  >
                    <div className="p-2 w-[15%]">{item.modelName ?? '-'}</div>
                    <div className="p-2 w-[23%]">{item.name ?? '-'}</div>
                    <div className="p-2 w-[22%]">{item.robotState ?? '-'}</div>
                    <div className="p-2 w-[7%]">
                      <span
                        className={`inline-block w-[9px] h-[9px] rounded-full ${matchStateColor(item.robotStateColor)}`}
                      ></span>
                    </div>
                    <div className="p-2 w-[13%]">
                      {item.batteryLevel ? `${item.batteryLevel}%` : '-'}
                    </div>
                    <div className="p-2 w-[20%]">
                      {item.lastUpdateTime
                        ? dayjs(item.lastUpdateTime).format('MM-DD HH:mm:ss')
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            {commandList.map((command: MultiRobotCommandType) => {
              switch (command.name) {
                case 'COMMAND_TASK':
                  return (
                    <Button
                      key={command.name}
                      className={'buttonSwitchPrimary w-[110px]'}
                      onClick={() => handleInputInfo(command.name)}
                      type="primary"
                      disabled={false}
                    >
                      {t('Input_Info')}
                    </Button>
                  );
                case 'COMMAND_ADD_INFO':
                  return (
                    <Fragment key={command.name}>
                      {selectedTaskDetailData?.taskMainState ===
                        'TASK_POSSIBLE' && (
                        <Button
                          className={'buttonSwitchPrimary w-[110px]'}
                          onClick={() => handleExecuteTask(command.name)}
                          type="primary"
                          disabled={false}
                        >
                          {t('Execute_Task')}
                        </Button>
                      )}
                      {selectedTaskDetailData?.taskMainState ===
                        'TASK_IMPOSSIBLE' && (
                        <Button
                          className={'buttonSwitchPrimary w-[110px]'}
                          onClick={() => handleExecuteTask(command.name)}
                          type="primary"
                          disabled={true}
                        >
                          {t('Execute_Task')}
                        </Button>
                      )}
                      {selectedTaskDetailData?.taskMainState !==
                        'TASK_POSSIBLE' &&
                        selectedTaskDetailData?.taskMainState !==
                          'TASK_IMPOSSIBLE' && (
                          <Button
                            className={'buttonSwitchPrimary w-[110px]'}
                            onClick={handleStopTask}
                            type="primary"
                            disabled={false}
                          >
                            {t('Stop_Task')}
                          </Button>
                        )}
                    </Fragment>
                  );
                default:
                  return;
              }
            })}
          </div>
        </ErrorBoundary>
      ) : null}
    </div>
  );
}
