import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';

import { db } from '../../config/firebase-marosol';
import { newsType } from '../../_types';
import dayjs from 'dayjs';

export const getNewsData = async (): Promise<newsType[]> => {
  try {
    const storyCollection = collection(db, 'story_story');

    const newsQuery = query(
      storyCollection,
      where('story_category_id', '==', '7'),
      where('story_news_classify', 'array-contains', 'sollink'),
      where('is_published', '==', true),
      orderBy('created_at', 'desc'),
    );

    const { docs } = await getDocs(newsQuery);

    const newsData = docs.map((doc) => {
      const { title, created_at, thumbnail, tags, news_link } = doc.data();
      return {
        title,
        date: dayjs(created_at.toDate()).format('YYYY-MM-DD'),
        thumbnail,
        tags,
        link: news_link,
      };
    });

    return newsData;
  } catch (error) {
    console.log(error);
    throw new Error('데이터 가져오기에 실패했습니다.');
  }
};
