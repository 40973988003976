import { useEffect, useState, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import inputDeleteIcon from '../../assets/icon/icon_input_delete.svg';
import searchIcon from '../../assets/icon/icon_search.svg';
import useUserStore from '../../store/userStore';
import { Input } from 'antd';
import { FilterSiteType } from '../../_types';

interface PropsType {
  siteList: FilterSiteType[];
  handleChange: (
    setValue: (value: string) => void,
    item?: { name: string; id: string },
  ) => void;
  setInit?: (value: boolean) => void;
  setSelectedSite?: (value: string) => void;
  isReset?: boolean;
  setIsReset?: (value: boolean) => void;
  width?: number;
  height?: number;
  addClassName?: string;
}

export default function SearchInput({
  siteList,
  handleChange,
  setInit,
  setSelectedSite,
  isReset,
  setIsReset,
  width = 300,
  height = 38,
  addClassName,
}: PropsType) {
  const { changeSelectedUserAuthSites } = useUserStore();
  const { t } = useTranslation();

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [filterSiteList, setfilterSiteList] = useState<FilterSiteType[]>([]);
  const [selectedListIdx, setSelectedListIdx] = useState<number>(0);

  const [isKeyboardNavigating, setIsKeyboardNavigating] =
    useState<boolean>(false);

  const [recentKeywords, setRecentKeywords] = useState<FilterSiteType[]>(
    localStorage.getItem('recentKeywords')
      ? JSON.parse(localStorage.getItem('recentKeywords')!)
      : [],
  );

  const ulRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocusToggle = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const deleteInputValue = () => {
    setInputValue('');
  };

  const handleDeleteInput = () => {
    deleteInputValue();
    inputRef.current?.focus();
  };

  const handleSelectKeydown = (e: KeyboardEvent) => {
    if (!isDropdownVisible) return;

    switch (e.key) {
      case 'ArrowDown': {
        setIsKeyboardNavigating(true);
        setSelectedListIdx((prevIdx) =>
          prevIdx < filterSiteList.length - 1 ? prevIdx + 1 : prevIdx,
        );
        break;
      }
      case 'ArrowUp': {
        setIsKeyboardNavigating(true);
        setSelectedListIdx((prevIdx) => (prevIdx > 0 ? prevIdx - 1 : prevIdx));
        break;
      }
      default:
        break;
    }
  };

  const handleDeleteRecentKeyword = (recentKeyword: string): void => {
    setRecentKeywords(
      recentKeywords.filter((keyword) => keyword?.name !== recentKeyword),
    );
  };

  const updateRecentKeywords = (item: FilterSiteType): void => {
    if (recentKeywords.some(({ name }) => name === item.name)) return;

    setRecentKeywords([item, ...recentKeywords]);

    if (recentKeywords.length >= 5) {
      setRecentKeywords((prevKeywords) => prevKeywords.slice(0, -1));
    }
  };

  const filteredList = useMemo(() => {
    return siteList.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }, [siteList, inputValue]);

  useEffect(() => {
    deleteInputValue();
    setIsReset && setIsReset(false);
  }, [isReset]);

  useEffect(() => {
    const sortedList = siteList.sort((a, b) => a.name.localeCompare(b.name));
    setfilterSiteList(sortedList);
  }, [siteList]);

  useEffect(() => {
    setfilterSiteList(filteredList);
    setSelectedListIdx(0);
    if (inputValue === '') {
      /**
       * workflow에서만 임시로 분기처리
       * TODO: 유저사이트 리팩토링 할 때 컴포넌트 구조 변경
       */
      if (window.location.pathname === '/workflow') {
        changeSelectedUserAuthSites([]);
      } else {
        changeSelectedUserAuthSites(siteList.map((item: any) => item.id));
      }
      setInit && setInit(false);
      setSelectedSite && setSelectedSite('');
    }
  }, [inputValue]);

  useEffect(() => {
    document.addEventListener('keydown', handleSelectKeydown);

    return () => {
      document.removeEventListener('keydown', handleSelectKeydown);
    };
  }, [isDropdownVisible, filterSiteList.length, inputValue]);

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (document.activeElement !== inputRef.current || e.key !== 'Enter')
        return;

      inputRef.current?.blur();

      if (selectedListIdx < recentKeywords.length) {
        handleChange(
          setInputValue,
          ...filterSiteList.filter(
            ({ name }) => name === recentKeywords[selectedListIdx].name,
          ),
        );
        return;
      }

      if (!setInit && selectedListIdx === recentKeywords.length) {
        handleChange(setInputValue);
        return;
      }

      handleChange(
        setInputValue,
        filterSiteList[
          selectedListIdx - recentKeywords.length - (!setInit ? 1 : 0)
        ],
      );
      updateRecentKeywords(
        filterSiteList[
          selectedListIdx - recentKeywords.length - (!setInit ? 1 : 0)
        ],
      );
    };

    document.addEventListener('keydown', handleEnter);

    if (ulRef.current) {
      const itemHeight = 32;
      const scrollTop = ulRef.current.scrollTop;
      const offsetTop = selectedListIdx * itemHeight;

      if (offsetTop < scrollTop) {
        ulRef.current.scrollTop = offsetTop;
      } else if (
        offsetTop + itemHeight >
        scrollTop + ulRef.current.clientHeight
      ) {
        ulRef.current.scrollTop =
          offsetTop + itemHeight - ulRef.current.clientHeight;
      }
    }

    return () => {
      setIsKeyboardNavigating(false);
      document.removeEventListener('keydown', handleEnter);
    };
  }, [filterSiteList, selectedListIdx, inputValue]);

  useEffect(() => {
    localStorage.setItem('recentKeywords', JSON.stringify(recentKeywords));
  }, [recentKeywords]);

  return (
    <div className="relative">
      <div className="relative">
        <Input
          size={'large'}
          type="text"
          value={inputValue}
          placeholder="사이트 검색"
          className={`w-[${width}px] px-3 py-2 text-sm h-[${height}px] ${addClassName} shadow-sm`}
          onFocus={handleInputFocusToggle}
          onBlur={handleInputFocusToggle}
          onChange={handleInputChange}
          // ref={inputRef}
        ></Input>

        {inputValue ? (
          <button onClick={handleDeleteInput}>
            <img
              className="absolute top-[8px] right-[8px]"
              src={inputDeleteIcon}
              key="deleteIcon"
            ></img>
          </button>
        ) : (
          <img
            className="absolute top-[8px] right-[8px]"
            src={searchIcon}
            key="searchIcon"
          ></img>
        )}
      </div>

      {isDropdownVisible && filterSiteList.length > 0 && (
        <ul
          className={`w-[${width}px] max-h-96 py-2 mt-2 mr-5 text-sm border border-white rounded-lg shadow-sm absolute z-10 bg-white overflow-auto scrollbar-hide`}
          ref={ulRef}
        >
          {recentKeywords &&
            inputValue.length === 0 &&
            recentKeywords.map((recentKeyword, idx) => (
              <li
                key={idx}
                style={{ color: '#45AEA3' }}
                className={`h-8 px-2 rounded-lg relative ${
                  selectedListIdx === idx ? 'bg-gray-100' : ''
                }`}
                onMouseEnter={() => {
                  setSelectedListIdx(idx);
                }}
              >
                <button
                  className="flex items-center w-full h-full px-1"
                  onMouseDown={() => {
                    handleChange(setInputValue, recentKeyword);
                  }}
                >
                  {recentKeyword?.name}
                </button>

                <button
                  className="absolute top-[5px] right-[15px]"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleDeleteRecentKeyword(recentKeyword?.name);
                  }}
                >
                  삭제
                </button>
              </li>
            ))}
          {!setInit && inputValue === '' && (
            <li
              className={`h-8 px-2 rounded-lg ${
                selectedListIdx === recentKeywords.length ? 'bg-gray-100' : ''
              }`}
              onMouseEnter={() => {
                setSelectedListIdx(recentKeywords.length);
              }}
            >
              <button
                className="flex items-center w-full h-full px-1"
                onMouseDown={() => {
                  handleChange(setInputValue);
                }}
              >
                {t('site_all')}
              </button>
            </li>
          )}
          {filterSiteList.map((item, idx) => (
            <li
              key={idx}
              className={`h-8 px-2 ${
                !setInit && inputValue === ''
                  ? idx + recentKeywords.length === selectedListIdx - 1
                    ? 'bg-gray-100'
                    : ''
                  : idx + recentKeywords.length === selectedListIdx
                    ? 'bg-gray-100'
                    : ''
              } rounded-lg truncate`}
              value={item.id}
              onMouseEnter={() => {
                if (!isDropdownVisible || isKeyboardNavigating) return;
                !setInit && inputValue === ''
                  ? setSelectedListIdx(idx + recentKeywords.length + 1)
                  : setSelectedListIdx(idx + recentKeywords.length);
              }}
            >
              <button
                className="flex items-center w-full h-full px-1"
                onMouseDown={() => {
                  handleChange(setInputValue, item);
                  updateRecentKeywords(item);
                }}
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
