import { useEffect, useState } from "react";
import useUserStore from "../store/userStore";

import { t } from "i18next";

import { getAllSiteList } from "../_api/firestore/site";
import useUserData from "../hooks/useUserData";
import {
  CurrentTask,
  FirestoreCollenctions,
  Permission,
  ScheduleSocketData,
} from "../_types";
import { db } from "../config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import SearchInput from "../components/common/SearchInput";
import ModalPortal from "../components/modal/ModalPortal";
import Modal from "../components/modal/Modal";
import useAuth from "../hooks/useAuth";
import InquiryModal from "../components/modal/InquiryModal";
import { Tabs } from "antd";
import Progress from "../components/schedule/Progress";
// import Completed from "../components/schedule/Completed";
import Notification from "../components/schedule/Notification";
import smileIcon from "../assets/icon/icon_smile.svg";
import Empty from "../components/empty";
import {SECOND_SOCKET_URL} from "../_api/webSocket";
import useScheduleStore from "../store/scheduleStore";
import {io} from "socket.io-client";

const SchedulePage = () => {
  const { userStatus, selectedUserAuthSite, changeSelectedUserAuthSites } =
    useUserStore();

  const { user } = useUserData();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const [siteList, setSiteList] = useState<{ name: string; id: string }[]>([]);
  const { updateScheduleData, scheduleData } = useScheduleStore();
  const [init, setInit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [curentTaskList, setCurrentList] = useState<CurrentTask[] | null>(null);

  useEffect(() => {
    if (selectedUserAuthSite.length !== 1) return;
    if (typeof scheduleData === "string") {
      setCurrentList(
        scheduleData
          ? JSON.parse(scheduleData).find(
              (obj: ScheduleSocketData) =>
                obj.siteId === selectedUserAuthSite[0]
            )?.currentTask
          : null
      );
    } else {
      setCurrentList(
        scheduleData
          ? (scheduleData?.find(
              (obj: ScheduleSocketData) =>
                obj.siteId === selectedUserAuthSite[0]
            )?.currentTask as CurrentTask[])
          : null
      );
    }
  }, [scheduleData, selectedUserAuthSite]);

  useEffect(() => {
    setLoading(true);
    if (
      scheduleData?.find(
        (obj: ScheduleSocketData) => obj.siteId === selectedUserAuthSite[0]
      )?.currentTask as CurrentTask[]
    ) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [scheduleData, selectedUserAuthSite]);

  useEffect(() => {

    const siteSocket = io(`${SECOND_SOCKET_URL}/sites`);

    const onConnect = () => {
      console.log("connected");
    };

    const getSocketData = (data: ScheduleSocketData[]) => {
      updateScheduleData(data);
    };
    siteSocket.connect();
    siteSocket.on("connect", onConnect);
    siteSocket.on("sites", getSocketData);

    return () => {
      siteSocket.disconnect();
      siteSocket.off("connect", onConnect);
      siteSocket.off("sites", getSocketData);
    };
  }, [selectedUserAuthSite]);

  useEffect(() => {
    if (user?.permission === Permission.SUPER_ADMIN) {
      getAllSiteList().then((data) => {
        changeSelectedUserAuthSites(data.map((site) => site.id as string));
        setSiteList(
          data.map((doc) => ({ id: doc.id as string, name: doc.name }))
        );
      });

      return;
    }

    const fetchData = async (inputEmail: string) => {
      try {
        const sitesRef = collection(db, FirestoreCollenctions.USERS);
        const q = query(sitesRef, where("email", "==", inputEmail));

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((item) => item.data());
      } catch (error) {
        console.error(error);
      }
    };

    if (
      user &&
      (user.permission === Permission.ENGINEER ||
        userStatus === Permission.USER)
    ) {
      const list: { name: string; id: string }[] = [];
      try {
        fetchData(user.email).then(async (res) => {
          if (res && res[0].auth_sites) {
            for (let i = 0; i < res[0].auth_sites.length; i++) {
              const docRef = doc(
                db,
                FirestoreCollenctions.SITES,
                res[0].auth_sites[i]
              );

              const sites = await getDoc(docRef);
              list.push({ name: sites.data()?.name, id: sites.id });
            }
          }
          changeSelectedUserAuthSites(
            list.map((item: { name: string; id: string }) => item.id)
          );
          setSiteList(list);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [user, userStatus]);

  const handleChange = (
    setValue: (name: string) => void,
    item?: { name: string; id: string }
  ) => {
    if (item) {
      changeSelectedUserAuthSites([item.id]);
      setValue(item.name);
      setInit(true);
      return;
    }
    setInit(false);
  };

  const tabItems = [
    {
      key: "1",
      label: t("Ongoing_Work"),
      children: (
        <>
          {init ? (
            <Progress
              loading={loading}
              curentTaskList={curentTaskList as CurrentTask[]}
            />
          ) : (
            <div className="flex justify-center w-full pt-[120px]">
              <Empty
                mainText={t("Please_choose_a_site")}
                subText={t("You_can_check_the_work_of_the_selected_site")}
              >
                {<img src={smileIcon} className="w-[48px]  py-4" />}
              </Empty>
            </div>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: t("Completed_Work"),
      children: (
        <>
          {init ? (
            // <Completed selectedSite={selectedUserAuthSite[0]} />
            <Notification />
          ) : (
            <div className="flex justify-center w-full pt-[120px]">
              <Empty
                mainText={t("Please_choose_a_site")}
                subText={t("You_can_check_the_work_of_the_selected_site")}
              >
                {<img src={smileIcon} className="w-[48px]  py-4" />}
              </Empty>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="w-full pl-[75px] flex flex-col bg-neutral-50 h-screen pb-2">
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t("history")}
      </div>

      <section className="pt-[91px] w-full px-12">
        <Tabs
          items={tabItems}
          tabBarExtraContent={
            <SearchInput
              siteList={siteList}
              handleChange={handleChange}
              setInit={setInit}
              addClassName={'border-none'}
            />
          }
        />
      </section>

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === "false" || hasAuthRobot === "false") &&
        !(login === "false") && <InquiryModal />}
    </div>
  );
};

export default SchedulePage;
