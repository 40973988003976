import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "../config/firebase";

import useUserStore from "../store/userStore";
import useAuth from "../hooks/useAuth";
import useUserData from "../hooks/useUserData";
import usePeriod, { dateFormat, getToday } from "../hooks/usePeriod";
import { Permission } from "../_types/user";

import { FirestoreCollenctions } from "../_types/common";
import { getAllSiteList } from "../_api/firestore/site";

import InquiryModal from "../components/modal/InquiryModal";
import ChartContainer from "../components/dashboard/ChartContainer";
import { PeriodType } from "../_types/dashBoard";
import SearchInput from "../components/common/SearchInput";

export default function DashboardPage() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { userStatus, changeSelectedUserAuthSites } = useUserStore();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();
  const { startDate, endDate } = usePeriod();
  const { user } = useUserData();

  const [siteList, setSiteList] = useState<{ name: string; id: string }[]>([]);

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(lastMonth.getDate() + 1);

  const [period, setPeriod] = useState<PeriodType>({
    startDate: dateFormat(lastMonth),
    endDate: getToday(),
  });

  useEffect(() => {
    setPeriod({ startDate, endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    if (user?.permission === Permission.SUPER_ADMIN) {
      getAllSiteList().then((data) => {
        changeSelectedUserAuthSites(data.map((site) => site.id as string));
        setSiteList(
          data.map((doc) => ({ id: doc.id as string, name: doc.name }))
        );
      });

      return;
    }

    const fetchData = async (inputEmail: string) => {
      const sitesRef = collection(db, FirestoreCollenctions.USERS);
      const q = query(sitesRef, where("email", "==", inputEmail));

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((item) => item.data());
    };

    if (
      user &&
      (user.permission === Permission.ENGINEER ||
        userStatus === Permission.USER)
    ) {
      const temp: any = [];

      fetchData(user.email).then(async (res) => {
        if (res[0].auth_sites) {
          for (let i = 0; i < res[0].auth_sites.length; i++) {
            const docRef = doc(
              db,
              FirestoreCollenctions.SITES,
              res[0].auth_sites[i]
            );
            const sites: any = await getDoc(docRef);
            temp.push({ name: sites.data()?.name, id: sites.id });
          }
        }
        changeSelectedUserAuthSites(temp.map((item: any) => item.id));
        setSiteList(temp);
      });
    }
  }, [user, userStatus]);

  const handleChange = (
    setValue: (value: string) => void,
    item?: { name: string; id: string }
  ) => {
    if (item) {
      changeSelectedUserAuthSites([item.id]);
      setValue(item.name);
      return;
    }
    changeSelectedUserAuthSites(siteList.map((item: any) => item.id));
    setValue("사이트 전체");
  };

  const handleChangeDate = (newValue: DateValueType) => {
    navigate(
      `/dashboard?start=${newValue?.startDate}&end=${newValue?.endDate}`
    );
  };

  return (
    <div className="w-[100%] pl-[75px] bg-neutral-50">
      {(hasAuthSite === "false" || hasAuthRobot === "false") &&
        !(login === "false") && <InquiryModal />}
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t("dashboard")}
      </div>

      <section
        className="pt-[90px] flex items-center justify-between pb-3 pl-12"
        data-name="bg"
      >
        {/* 사이트 필터 */}
        <SearchInput siteList={siteList} handleChange={handleChange} addClassName={'border-none'}/>

        <div className="pr-12 min-w-[300px]">
          <Datepicker
            value={period}
            inputClassName={
              "w-full  py-2 px-3 text-sm focus:bg-[#ebf7f6] placeholder:text-sm rounded-lg sm:text-sm shadow-sm "
            }
            placeholder={t("please_select_a_period")}
            primaryColor={"teal"}
            useRange={false}
            showShortcuts={true}
            onChange={handleChangeDate}
            i18n={`${i18next.resolvedLanguage}`}
            dateLooking="middle"
            configs={{
              shortcuts: {
                today: `${t("today")}`,
                yesterday: `${t("yesterday")}`,
                currentMonth: `${t("currentMonth")}`,
                pastMonth: `${t("pastMonth")}`,
                past: (period) => `${t("the_last")} ${period}${t("days")}`,
              },
            }}
          />
        </div>
      </section>
      <ChartContainer period={period} />
    </div>
  );
}
