import styled from "styled-components";

export const RobotApiWrapper = styled.div`
  width: 100vw;
  height: 1860px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 1400px) {
    height: 1600px;
  }
  @media (max-width: 1200px) {
    height: 1400px;
  }
  @media (max-width: 550px) {
    height: 700px;
  }
`
export const RobotApiTxtArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  text-align: center;
  @media (max-width: 800px) {
    gap: 30px;
  }
  @media (max-width: 550px) {
    gap: 12px;
  }
`
export const RobotApiTitle = styled.p`
  background: linear-gradient(122deg, #27C2A5 14.4%, #20B5E4 89.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 55px;
  font-weight: 800;
  line-height: 48px;
  @media (max-width: 800px) {
    font-size: 35px;
  }
  @media (max-width: 550px) {
    font-size: 24px;
  }
`
export const RobotApiSubTitle = styled.p`
  color: #111;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (max-width: 550px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const RobotApiDownBtn = styled.button`
  cursor: pointer;
  border-radius: 50px;
  background: linear-gradient(127deg, #1BBA9C 9.35%, #21A9D3 91.16%);
  display: flex;
  width: 450px;
  padding: 26px 32px 26px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 85.714% */
  border: none;
  margin-top: 155px;
  @media (max-width: 800px) {
    width: 280px;
    border-radius: 30px;
    font-size: 22px;
    gap: 5px;
    padding: 18px;
  }
  @media (max-width: 550px) {
    margin-top: -80px;
  }
  @media (max-width: 500px) {
    width: 220px;
    border-radius: 30px;
    font-size: 18px;
    gap: 5px;
    padding: 11px;
  }
`