import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import SearchInput from '../common/SearchInput';
import useRobotstore from '../../store/robotStore';
import useUserStore from '../../store/userStore';
import useLoginStore from '../../store/loginStore';
import { SiteType } from '../../_types/site';
import { AuthType, Permission } from '../../_types/user';
import { FirestoreCollenctions, multiRobotTaskType } from '../../_types';
import { getAllSiteListOnSnapshot } from '../../_api/firestore/site';
import { getUserById } from '../../_api/firestore/user';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { SECOND_SOCKET_URL } from '../../_api/webSocket';
import { Socket, io } from 'socket.io-client';
import MultiRobotSkeleton from '../loading/skeleton/MultiRobotSkeleton';

const MultiRobotWork = () => {
  const { userStatus, selectedUserAuthSite, changeSelectedUserAuthSites } =
    useUserStore();
  const { userInfo } = useLoginStore();
  const {
    updateIsShowMultiRobotDetail,
    selectedTask,

    changeSelectedTaskDetailData,
    changeSelectedTask,
  } = useRobotstore();

  const [userData, setUserData] = useState<AuthType | null>(null);
  const [siteList, setSiteList] = useState<SiteType[]>([]);
  const [filterSiteList, setfilterSiteList] = useState<
    { name: string; id: string }[]
  >([]);
  const [taskData, setTaskData] = useState<multiRobotTaskType[] | null>(null);
  const [init, setInit] = useState<boolean>();

  const multiRobotTaskSocket = useRef<Socket | null>(null);
  const selectedSite = useRef<string | null>(null);

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (!userData) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      getAllSiteListOnSnapshot(setSiteList);
      return;
    }

    const fetchData = async () => {
      const siteData: SiteType[] = [];

      userData.auth_sites.map(async (siteId) => {
        const docRef = doc(db, FirestoreCollenctions.SITES, siteId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = {
            id: docSnapshot.id,
            ...(docSnapshot.data() as SiteType),
          };

          siteData.push(data);
        } else {
          return null;
        }

        setSiteList(siteData as SiteType[]);
      });
    };

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      fetchData();
    }
  }, [userData, userStatus]);

  useEffect(() => {
    setfilterSiteList(
      siteList.map((item) => ({ name: item.name, id: item.id || '' })),
    );
  }, [siteList]);

  useEffect(() => {
    if (selectedSite.current !== selectedUserAuthSite[0]) {
      setTaskData(null);
      selectedSite.current = selectedUserAuthSite[0];
    }

    multiRobotTaskSocket.current?.off('tasks');

    if (selectedUserAuthSite.length === 1) {
      multiRobotTaskSocket.current?.emit('sites', [selectedUserAuthSite[0]]);
    }
    if (selectedUserAuthSite.length !== 1 && filterSiteList.length > 0) {
      multiRobotTaskSocket.current?.emit('sites', [filterSiteList[0].id]);
    }

    multiRobotTaskSocket.current?.on('tasks', (data: multiRobotTaskType[]) => {
      setTaskData(data);

      if (selectedTask) {
        changeSelectedTaskDetailData(
          data.filter((item) => item.taskId === selectedTask)[0],
        );
      }
    });
  }, [selectedUserAuthSite, selectedTask, filterSiteList]);

  useEffect(() => {
    multiRobotTaskSocket.current = io(`${SECOND_SOCKET_URL}/tasks`);

    multiRobotTaskSocket.current.on('connect', () => {
      console.log('task data connect');
    });

    return () => {
      multiRobotTaskSocket.current?.disconnect();
      multiRobotTaskSocket.current?.off('tasks');
    };
  }, []);

  const handleChange = (
    setValue: (name: string) => void,
    item?: { name: string; id: string },
  ) => {
    if (item) {
      changeSelectedUserAuthSites([item.id]);
      setValue(item.name);
      setInit(true);
      return;
    }
    setInit(false);
  };

  // const style = !selectedTask
  //   ? 'w-[0px]'
  //   : `z-[100] fixed right-0 w-[650px] h-[100vh] p-6 px-7 bg-white flex flex-col drop-shadow overflow-scroll ${
  //       isShowMultiRobotDetail ? 'robot-menu-on' : 'robot-menu-off'
  //     } `;

  return (
    <>
      <section
        className="flex items-center justify-between gap-5 pb-3"
        data-name="bg"
      >
        <SearchInput
          siteList={filterSiteList}
          handleChange={handleChange}
          setInit={setInit}
          addClassName={'border-none'}
        />

        <div className="flex justify-end w-full gap-2 pr-10 text-xs">
          <span>{t('last_update')} </span>
          <span>{dayjs(new Date()).format('MM-DD HH:mm:ss')}</span>
        </div>
      </section>

      <div className="pt-3 pb-7">
        <div className="text-center text-sm w-[98%]">
          <div className="flex items-center font-bold text-center shadow-sm bg-neutral-200 rounded-xl">
            <div className="py-3 w-[15%]">{t('task_name')}</div>
            <div className="py-3 w-[50%]">{t('Work_Detail')}</div>
            <div className="px-2 py-3 w-[15%]">{t('Work_Status')}</div>
            <div className="py-3 w-[20%]">{t('Put_Robot')}</div>
          </div>

          {taskData !== null ? (
            taskData.length > 0 ? (
              <div>
                {taskData?.map((item: multiRobotTaskType, idx: number) => (
                  <div
                    key={idx}
                    onClick={() => {
                      updateIsShowMultiRobotDetail(true);
                      changeSelectedTask(item.taskId);
                    }}
                    data-name="list"
                    className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] ${
                      localStorage.login === 'true' &&
                      localStorage.verified === 'true'
                        ? 'justify-center mt-1 rounded-xl shadow-sm'
                        : 'blur-sm '
                    }`}
                  >
                    <div className="py-3 w-[15%]">{item.taskName}</div>
                    <div className="py-3 w-[50%]">{item.description}</div>
                    <div className="py-3 w-[15%]">
                      <span
                        className={`inline-block min-w-[52px] h-[32px] px-3 leading-8 rounded-full font-bold ${item.taskMainState === 'TASK_POSSIBLE' ? 'bg-[#CBF2E5] text-[#26BFA1]' : item.taskMainState === 'TASK_IMPOSSIBLE' ? 'bg-[#FFEAE6] text-[#FF3029]' : 'bg-white text-[#26BFA1] border border-[#26BFA1]'}`}
                      >
                        {item.taskMainStateAlias}
                      </span>
                    </div>
                    <div className="py-3 w-[20%]">
                      {item.robotList.length > 0
                        ? item.robotList.map((robot) => robot.name).join(', ')
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  fontSize: '60px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontFamily: 'SUIT',
                  height: '400px',
                  lineHeight: 5,
                  color: '#e0e0e0',
                }}
              >
                NO DATA
              </div>
            )
          ) : (
            <MultiRobotSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

export default MultiRobotWork;
