import core from "../../config/axios";

export interface workHistoryParams {
  siteId: string;
  startDate: string;
  endDate: string;
  robotModelTypeEn?: string;
  alarmType?: string;
}

const { isClient } = core;

const getWorkHistoryData = async (params: workHistoryParams): Promise<any> => {
  try {
    const res = await isClient.get(`/task/complete-list`, { params });
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error("완료된 작업 이력을 불러오지 못했습니다.");
  }
};

export { getWorkHistoryData };
