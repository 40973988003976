import React, { useEffect, useRef, useState } from 'react';
import Home from '../organism/Home';
import TopNavigation from '../components/introduce/TopNavigation';
import styled from 'styled-components';
import {
  GoTopBtn,
  IsDemoContent,
  IsDemoImg,
  IsDemoNormal,
  IsDemoRequestBtn,
  IsDemoSI,
  IsDemoTitle,
  IsDemoWrapper,
  MobileMenuWrapperBlack,
} from '../components/introduce/styles';
import '../assets/introduce/pretendardvariable.css';
import { createGlobalStyle } from 'styled-components';
import Experience from '../organism/Experience/Experience';
import { DisContent } from '../organism/Page3/DisContent';
import Polygon from '../components/introduce/Polygon';
import Banner from '../organism/Page4/Banner';
import { ManageRobot } from '../organism/Page5/ManageRobot';
import { RobotApi } from '../organism/Page6/RobotApi';
import { Technical } from '../organism/Page7/Technical';
import Map from '../organism/Page8/Map';
import Expansion from '../organism/Page9/Expansion';
import Issue from '../organism/Page10/Issue';
import LinkerModal from '../components/introduce/modal/LinkerModal';
import PlatformModal from '../components/introduce/modal/PlatformModal';
import EngineModal from '../components/introduce/modal/EngineModal';
import BuilderModal from '../components/introduce/modal/BuilderModal';
import PendantModal from '../components/introduce/modal/PendantModal';
import IsNormalCompany from '../assets/introduce/Image/IsNormalCompany.png';
import IsRobotCompany from '../assets/introduce/Image/IsRobotCompany.png';
import { onClickApplyMemo } from '../util/analytics';
import { MobileLinkerModal } from '../components/introduce/modal/Mobile/MobileLinkerModal';
import { MobilePlatformModal } from '../components/introduce/modal/Mobile/MobilePlatformModal';
import { MobileEngineModal } from '../components/introduce/modal/Mobile/MobileEngineModal';
import { MobileBuilderModal } from '../components/introduce/modal/Mobile/MobileBuilderModal';
import { MobilePendantModal } from '../components/introduce/modal/Mobile/MobilePendantModal';

interface GlobalStyleProps {
  clicked: string; // Define the type for 'clicked' prop
}

const IntroducePage = () => {
  const [clicked, setClicked] = useState('');
  const [isDemo, setIsDemo] = useState(false);
  const demoRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (demoRef.current && !demoRef.current.contains(event.target as Node)) {
        setIsDemo(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <GlobalStyle clicked={clicked} />
      <MainWrapper>
        <GoTopBtn onClick={scrollToTop}>TOP</GoTopBtn>
        <TopNavigation setIsDemo={setIsDemo} />
        {isDemo && (
          <>
            <MobileMenuWrapperBlack
              style={{ display: windowWidth >= 768 ? 'none' : 'block' }}
              onClick={() => setIsDemo(false)}
            />
            <IsDemoWrapper ref={demoRef}>
              <IsDemoSI>
                <IsDemoTitle>로봇 제조사 & SI 기업</IsDemoTitle>
                <IsDemoContent>
                  귀사의 제품과 자동화 프로젝트에 맞는
                  <br />
                  SOLlink 도입을 안내해 드립니다.
                </IsDemoContent>
                <IsDemoImg src={IsRobotCompany} />
                <IsDemoRequestBtn onClick={onClickApplyMemo}>
                  데모신청하기
                </IsDemoRequestBtn>
              </IsDemoSI>
              <IsDemoNormal>
                <IsDemoTitle>수요 기업</IsDemoTitle>
                <IsDemoContent>
                  로봇 자동화를 위한 컨설팅과
                  <br />
                  SOLlink 도입을 상담해 드립니다.
                </IsDemoContent>
                <IsDemoImg src={IsNormalCompany} />
                <IsDemoRequestBtn onClick={onClickApplyMemo}>
                  데모신청하기
                </IsDemoRequestBtn>
              </IsDemoNormal>
            </IsDemoWrapper>
          </>
        )}
        <Home />
        <Experience />

        {/* Page3 */}
        <DisContent />

        {/* 중간 역삼각형 */}
        <Polygon />

        {/* Page4 */}
        <Banner />

        {/* Page5 */}
        <ManageRobot />

        {/* Page6 */}
        <RobotApi />

        {/* Page7 */}
        <Technical setClicked={setClicked} />

        {/* Page9 -- 5차 수정 요청에 의해 순서 변경 */}
        <Expansion />

        {/* Page8 -- 5차 수정 요청에 의해 순서 변경 */}
        <Map />

        {/* Page10 */}
        <Issue />

        {clicked == 'linker' && <LinkerModal setClicked={setClicked} />}
        {clicked == 'platform' && <PlatformModal setClicked={setClicked} />}
        {clicked == 'engine' && <EngineModal setClicked={setClicked} />}
        {clicked == 'builder' && <BuilderModal setClicked={setClicked} />}
        {clicked == 'pendant' && <PendantModal setClicked={setClicked} />}
        {clicked == 'mobileLinker' && (
          <MobileLinkerModal setClicked={setClicked} />
        )}
        {clicked == 'mobilePlatform' && (
          <MobilePlatformModal setClicked={setClicked} />
        )}
        {clicked == 'mobileEngine' && (
          <MobileEngineModal setClicked={setClicked} />
        )}
        {clicked == 'mobileBuilder' && (
          <MobileBuilderModal setClicked={setClicked} />
        )}
        {clicked == 'mobilePendant' && (
          <MobilePendantModal setClicked={setClicked} />
        )}
      </MainWrapper>
    </>
  );
};

export default IntroducePage;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    font-family: 'Pretendard';
    overflow-x: hidden;
    overflow-y: ${(props) => (props.clicked === '' ? 'auto' : 'hidden')};
    user-select: none;
  }

  p, h1, h2, h3, h4, h5, h6, span {
    margin: 0;
  }
`;
export const MainWrapper = styled.div`
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  > div {
    overflow: hidden !important;
  }
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
