import React, { useEffect, useState } from 'react';
import { AiFillSchedule, AiOutlineBars } from 'react-icons/ai';
import { FaListCheck, FaUserCheck } from 'react-icons/fa6';
import {
  MdEventRepeat,
  MdLibraryAddCheck,
  MdOutlineCleaningServices,
  MdOutlineDoorSliding,
  MdPermMedia,
} from 'react-icons/md';
import { PiElevatorFill } from 'react-icons/pi'; //hall call
import { FaElevator } from 'react-icons/fa6'; //car call
import { BiDoorOpen, BiSolidTimer } from 'react-icons/bi';
import { RiDoorClosedLine, RiRemoteControlLine } from 'react-icons/ri';
import { BsDatabaseFillCheck } from 'react-icons/bs';
import {
  IoCaretForwardCircle,
  IoMoveSharp,
  IoStopCircle,
} from 'react-icons/io5';
import { RiBattery2ChargeFill } from 'react-icons/ri';
import { BsSignStopFill } from 'react-icons/bs';
import { MdHome } from 'react-icons/md';
import {
  TbEaseInOutControlPoints,
  TbEaseOutControlPoint,
  TbStairsUp,
} from 'react-icons/tb';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { Collapse, Divider } from 'antd';
import { text } from 'node:stream/consumers';
import useWorkflowStore from '../../store/workflowStore';
import errorPage from '../../pages/ErrorPage';
import { getBuilderNodeList } from '../../_api/rest/workflow';
import useUserStore from '../../store/userStore';

const { Panel } = Collapse;
/*SVG*/

const ItemBuilder = (props: any) => {
  const { robotInfo, robotType, changeRobotInfo, changeSiteNodeInfoList } =
    useWorkflowStore();
  const { selectedUserAuthSite } = useUserStore();
  const [nodeList, setNodeList] = useState<any>([]);

  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.effectAllowed = 'move';
  };

  useEffect(() => {
    if (props.isReset) {
      // setRobotNodeList([]);
      // setElvNodeList([]);
      // setPlcNodeList([]);
      // setCommonNodeList([]);
      // setAutoDoorNodeList([]);

      setNodeList([]);
    }
  }, [props.isReset]);

  useEffect(() => {
    if (robotType === 'single' && selectedUserAuthSite[0] && robotInfo) {
      (async () => {
        await getNodeListAPI(selectedUserAuthSite[0], robotInfo.deviceId);
      })();
    }

    if (robotType === 'multiple' && selectedUserAuthSite[0] && !robotInfo) {
      (async () => {
        await getNodeListAPI(selectedUserAuthSite[0], '');
      })();
    }
  }, [robotInfo, selectedUserAuthSite]);

  useEffect(() => {
    if (robotType === 'single') {
      changeRobotInfo(null);
      setNodeList([]);
    } else {
      (async () => {
        await getNodeListAPI(selectedUserAuthSite[0], '');
      })();
    }
  }, [robotType]);

  const getNodeListAPI = async (siteId: string, deviceId: string) => {
    await getBuilderNodeList(siteId, deviceId)
      .then((res: any) => {
        if (res !== null && res !== undefined) {
          setNodeList(res);
          changeSiteNodeInfoList(res);
          setActiveKeys([]);
        }
      })
      .catch((error: string) => {
        errorPage();
      });
  };

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) {
      setNodeList([]);
      changeSiteNodeInfoList([]);
    }
  }, [selectedUserAuthSite[0]]);

  const iconMapping = (action: string) => {
    // const name = action.replace('NODE_', '');

    switch (action) {
      case 'MOVE': {
        return <IoMoveSharp size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'STOP': {
        return <BsSignStopFill size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CHARGE': {
        return <RiBattery2ChargeFill size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'HOME': {
        return <MdHome size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'HALL_CALL': {
        return <PiElevatorFill size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CAR_CALL': {
        return <FaElevator size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'OPEN_DOOR': {
        return <BiDoorOpen size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'KEEP_OPEN': {
        return <RiDoorClosedLine size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CHECK': {
        return <FaListCheck size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CHECK_SELECT': {
        return <MdLibraryAddCheck size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'SET_VALUE': {
        return <BsDatabaseFillCheck size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'IO_CONTROL': {
        return (
          <TbEaseInOutControlPoints size="20" color="rgb(100, 100, 100)" />
        );
        break;
      }
      case 'WAIT': {
        return <BiSolidTimer size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'ON_TIME': {
        return <MdEventRepeat size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'USER_SELECT': {
        return <FaUserCheck size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'AUTO_DOOR_OPEN': {
        return <MdOutlineDoorSliding size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'AUTO_DOOR_CLOSE': {
        return <MdOutlineDoorSliding size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CLEAN': {
        return (
          <MdOutlineCleaningServices size="20" color="rgb(100, 100, 100)" />
        );
        break;
      }
      case 'CONTENTS_PLAY': {
        return <MdPermMedia size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CHANGE_MAP': {
        return <FaMapMarkedAlt size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'CHANGE_FLOOR': {
        return <TbStairsUp size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'APP_CONTROL': {
        return <RiRemoteControlLine size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'OUT_CONTROL': {
        return <TbEaseOutControlPoint size="20" color="rgb(100, 100, 100)" />;
        break;
      }
      case 'ROBOT|BACK_TO_SCHEDULE':
        return <AiFillSchedule size="20" color="rgb(100, 100, 100)" />;
        break;
    }

    return <IoStopCircle size="20" color="rgb(100, 100, 100)" />;
  };

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKeys(key);
  };

  return (
    <aside className={'wf-body-right-contents'}>
      <>
        <div
          id="START/END|START|시작"
          draggable={true}
          onDragStart={(event) => onDragStart(event, 'input')}
        >
          <div style={styles.parent}>
            <div style={styles.iconParent}>
              <IoCaretForwardCircle size="20" color="rgb(100, 100, 100)" />
            </div>
            시작
          </div>
        </div>

        <div
          id="START/END|END|종료"
          draggable={true}
          onDragStart={(event) => onDragStart(event, 'output')}
        >
          <div style={styles.parent}>
            <div style={styles.iconParent}>
              <IoStopCircle size="20" color="rgb(100, 100, 100)" />
            </div>
            종료
          </div>
        </div>
      </>

      <Collapse
        className={'mt-5 border-none bg-none'}
        defaultActiveKey={['1']}
        activeKey={activeKeys}
        onChange={handleCollapseChange}
      >
        {nodeList.map((item: any, idx: number) => {
          if (item.nodeGroupManufacturer === 'COMMON') {
            return (
              <Panel
                className={'bg-white border-none'}
                header={`${item.nodeGroupInfo.modelNameEn}(${item.nodeGroupInfo.modelNameKr})`}
                key={'key' + idx}
              >
                {item.nodeList.map((data: any, i: number) => {
                  // const name = data.nodeNameEn.replace('NODE_', '');

                  return (
                    <div
                      key={`node_${i}`}
                      id={`COMMON|${data.nodeNameEn}|${data.nodeNameKr}|${item.nodeGroupManufacturer}|${item.nodeGroupVersion}`}
                      draggable={true}
                      onDragStart={(event) => onDragStart(event, 'default')}
                    >
                      <div style={styles.parent}>
                        <div style={styles.iconParent}>
                          {iconMapping(data.nodeNameEn)}
                        </div>
                        {data.nodeNameKr}
                      </div>
                    </div>
                  );
                })}
              </Panel>
            );
          } else {
            return (
              <Panel
                className={'bg-white border-none'}
                header={
                  <>
                    {item.nodeGroupInfo.manufacturerKr} (
                    {item.nodeGroupInfo.modelNameKr})
                    <br />
                    <div
                      className={
                        'h-[24px] bg-[#26bfa1] text-white rounded-[6px] text-center content-center inline-block px-[7px]'
                      }
                    >
                      {item.nodeGroupInfo.modelTypeKr}
                    </div>
                  </>
                }
                key={'key' + idx}
              >
                {item.nodeList.map((data: any, i: number) => {
                  // const name = data.nodeNameEn.replace('NODE_', '');

                  return (
                    <div
                      key={`node_${i}`}
                      id={`${item.nodeGroupModelType}|${data.nodeNameEn}|${data.nodeNameKr}|${item.nodeGroupManufacturer}|${item.nodeGroupModelName}|${item.nodeGroupModelUuid}`}
                      draggable={true}
                      onDragStart={(event) => onDragStart(event, 'default')}
                    >
                      <div style={styles.parent}>
                        <div style={styles.iconParent}>
                          {iconMapping(data.nodeNameEn)}
                        </div>
                        {data.nodeNameKr}
                      </div>
                    </div>
                  );
                })}
              </Panel>
            );
          }
        })}
      </Collapse>

      {/*<Collapse ghost size={'large'} defaultActiveKey={'1'}*/}
      {/*    items={[{*/}
      {/*        key: '1', label: '공통',*/}
      {/*        children:*/}

      {/*            nodeList.forEach((data: any) => {*/}
      {/*                if(data.nodeGroupType === 'COMMON'){*/}
      {/*                    data.nodeList.map((item: any, idx: number)=> {*/}

      {/*                        const name = item.nodeNameEn.replace('NODE_', '');*/}

      {/*                        return (*/}
      {/*                            <div key={`node_${idx}`} id={`COMMON|${name}|${item.nodeNameKr}`} draggable={true}*/}
      {/*                                 onDragStart={(event) => onDragStart(event, 'default')}>*/}
      {/*                                <div style={styles.parent}>*/}
      {/*                                    <div style={styles.iconParent}>*/}
      {/*                                        {iconMapping(item.nodeNameEn)}*/}
      {/*                                    </div>*/}
      {/*                                    {item.nodeNameKr}*/}
      {/*                                </div>*/}
      {/*                            </div>*/}
      {/*                        )*/}
      {/*                    })*/}
      {/*                }*/}
      {/*            })*/}

      {/*    }]}*/}
      {/*/>*/}

      {/*/!* 공통 *!/*/}
      {/*{*/}
      {/*    commonNodeList.length > 0 ?*/}
      {/*        (<div style={styles.sidebarItemHeadingContainer}>*/}
      {/*            <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
      {/*                <span style={styles.sidebarItemHeading}> 공통 </span>*/}
      {/*            </div>*/}
      {/*        </div>) : ''*/}
      {/*}*/}
      {/*{*/}
      {/*    commonNodeList.map((item: any, idx: number) => {*/}
      {/*        const name = item.nodeNameEn.replace('NODE_', '');*/}

      {/*        return (*/}
      {/*            <div key={`node_${idx}`} id={`COMMON|${name}|${item.nodeNameKr}`} draggable={true}*/}
      {/*                 onDragStart={(event) => onDragStart(event, 'default')}>*/}
      {/*                <div style={styles.parent}>*/}
      {/*                    <div style={styles.iconParent}>*/}
      {/*                        {iconMapping(item.nodeNameEn)}*/}
      {/*                    </div>*/}
      {/*                    {item.nodeNameKr}*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        )*/}

      {/*    })*/}
      {/*}*/}
      {/*/!* 로봇 *!/*/}
      {/*{*/}
      {/*    robotNodeList.length > 0 ?*/}
      {/*        (<div style={styles.sidebarItemHeadingContainer}>*/}
      {/*            <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
      {/*                <span style={styles.sidebarItemHeading}> 로봇 </span>*/}
      {/*            </div>*/}
      {/*        </div>) : ''*/}
      {/*}*/}
      {/*{*/}
      {/*    robotNodeList.map((item: any, idx: number) => {*/}
      {/*        const name = item.nodeNameEn.replace('NODE_', '');*/}

      {/*        return (*/}
      {/*            <div key={`node_${idx}`} id={`AMR|${name}|${item.nodeNameKr}`} draggable={true}*/}
      {/*                 onDragStart={(event) => onDragStart(event, 'default')}>*/}
      {/*                <div style={styles.parent}>*/}
      {/*                    <div style={styles.iconParent}>*/}
      {/*                        {iconMapping(item.nodeNameEn)}*/}
      {/*                    </div>*/}
      {/*                    {item.nodeNameKr}*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        )*/}

      {/*    })*/}
      {/*}*/}

      {/*/!* 엘리베이터 *!/*/}
      {/*{*/}
      {/*    elvNodeList.length > 0 ?*/}
      {/*        (<div style={styles.sidebarItemHeadingContainer}>*/}
      {/*            <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
      {/*                <span style={styles.sidebarItemHeading}> 엘리베이터 </span>*/}
      {/*            </div>*/}
      {/*        </div>) : ''*/}
      {/*}*/}
      {/*{*/}
      {/*    elvNodeList.map((item: any, idx: number) => {*/}
      {/*        const name = item.nodeNameEn.replace('NODE_', '');*/}

      {/*        return (*/}
      {/*            <div key={`node_${idx}`} id={`ELV|${name}|${item.nodeNameKr}`} draggable={true}*/}
      {/*                 onDragStart={(event) => onDragStart(event, 'default')}>*/}
      {/*                <div style={styles.parent}>*/}
      {/*                    <div style={styles.iconParent}>*/}
      {/*                        {iconMapping(item.nodeNameEn)}*/}
      {/*                    </div>*/}
      {/*                    {item.nodeNameKr}*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        )*/}

      {/*    })*/}
      {/*}*/}

      {/*/!* 자동문 *!/*/}
      {/*{*/}
      {/*    autoDoorNodeList.length > 0 ?*/}
      {/*        (<div style={styles.sidebarItemHeadingContainer}>*/}
      {/*            <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
      {/*                <span style={styles.sidebarItemHeading}> 자동문 </span>*/}
      {/*            </div>*/}
      {/*        </div>) : ''*/}
      {/*}*/}
      {/*{*/}
      {/*    autoDoorNodeList.map((item: any, idx: number) => {*/}
      {/*        const name = item.nodeNameEn.replace('NODE_', '');*/}

      {/*        return (*/}
      {/*            <div key={`node_${idx}`} id={`AUTODOOR|${name}|${item.nodeNameKr}`} draggable={true}*/}
      {/*                 onDragStart={(event) => onDragStart(event, 'default')}>*/}
      {/*                <div style={styles.parent}>*/}
      {/*                    <div style={styles.iconParent}>*/}
      {/*                        {iconMapping(item.nodeNameEn)}*/}
      {/*                    </div>*/}
      {/*                    {item.nodeNameKr}*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        )*/}

      {/*    })*/}
      {/*}*/}

      {/*/!* PLC *!/*/}
      {/*{*/}
      {/*    plcNodeList.length > 0 ?*/}
      {/*        (<div style={styles.sidebarItemHeadingContainer}>*/}
      {/*            <div style={{display: "flex"}}><AiOutlineBars size="20" color="rgb(100, 100, 100)"/>*/}
      {/*                <span style={styles.sidebarItemHeading}> PLC </span>*/}
      {/*            </div>*/}
      {/*        </div>) : ''*/}
      {/*}*/}
      {/*{*/}
      {/*    plcNodeList.map((item: any, idx: number) => {*/}
      {/*        const name = item.nodeNameEn.replace('NODE_', '');*/}

      {/*        return (*/}
      {/*            <div key={`node_${idx}`} id={`PLC|${name}|${item.nodeNameKr}`} draggable={true}*/}
      {/*                 onDragStart={(event) => onDragStart(event, 'default')}>*/}
      {/*                <div style={styles.parent}>*/}
      {/*                    <div style={styles.iconParent}>*/}
      {/*                        {iconMapping(item.nodeNameEn)}*/}
      {/*                    </div>*/}
      {/*                    {item.nodeNameKr}*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        )*/}

      {/*    })*/}
      {/*}*/}
    </aside>
  );
};

export default ItemBuilder;

const styles = {
  parent: {
    padding: 7,
    fontSize: 14,
    background: '#fff',
    cursor: 'move',
    marginTop: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
    fontFamily: 'Gmarket Sans, Medium',
  },
  iconParent: {
    borderRight: '1px solid rgb(230, 230, 230)',
    padding: 5,
    paddingRight: 10,
    paddingLeft: 5,
    marginRight: 10,
  },
  splitIcon: {
    transform: 'rotate(90deg)',
  },
  joinIcon: {
    transform: 'rotate(-90deg)',
  },
  sidebarItemHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: '10px',
    lineHeight: 'normal',
  },
  sidebarItemHeadingContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
};
