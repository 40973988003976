import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
} from 'firebase/auth';

import { db } from '../../config/firebase';
import useLoginStore from '../../store/loginStore';

import logo from '../../assets/image/SOLlink.png';
import googlelogo from '../../assets/image/google.png';
import { Permission, PermissionStatus } from '../../_types/user';
import { useTranslation } from 'react-i18next';
import { FirestoreCollenctions } from '../../_types';

export default function LoginModal() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const { updateIsShowLoginModal, updateIsLoggedIn } = useLoginStore();
  const { pathname } = useLocation();
  const { changeUserEmail } = useLoginStore();
  const { t } = useTranslation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          updateIsShowLoginModal(false);
          updateIsLoggedIn(true);
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-login-credentials':
              alert(t('email_or_password_does_not_match'));
              return;
            case 'auth/email-already-in-use':
              alert(t('this_email_is_already_in_use'));
              return;
            case 'auth/network-request-failed':
              alert(t('network_connection_failed'));
              return;
            case 'auth/invalid-email':
              alert(t('invalid_email_format'));
              return;
            case 'auth/internal-error':
              alert(t('invalid_request'));
              return;
            default:
              alert(t('sign_in_failed'));
              return;
          }
        });
    }
  };

  const auth = getAuth();

  const checkUserExist = async (user: any) => {
    const docRef = doc(db, FirestoreCollenctions.USERS, user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      if (!auth.currentUser.emailVerified) {
        changeUserEmail(auth.currentUser.email);
      }
    }
  }, [auth]);

  const handleGoogleLogIn = () => {
    try {
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider).then(async (res) => {
        const isExist = await checkUserExist(res.user);

        if (!isExist) {
          setDoc(doc(db, FirestoreCollenctions.USERS, res.user.uid), {
            auth_robots: [],
            auth_sites: [],
            display_name: res.user.displayName,
            email: res.user.email,
            joined_at: new Date(),
            permission: Permission.USER,
            phone: null,
            status: PermissionStatus.JOIN,
            updated_at: null,
            updated_by: null,
          });
        }
        updateIsShowLoginModal(false);
        updateIsLoggedIn(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const closeLoginModal = (event: any) => {
    const name = event.target.dataset.name;
    if (pathname === '/' && name === 'background') {
      updateIsShowLoginModal(false);
    }
  };

  const navigator = useNavigate();
  const handleNewAccount = () => {
    navigator('/signup');
  };

  const [effectStyle, setEffectStyle] = useState(
    'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center scale',
  );
  useEffect(() => {
    if (
      effectStyle ===
      'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center'
    ) {
      setEffectStyle(
        'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center scale',
      );
    } else {
      setEffectStyle(
        'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center',
      );
      setTimeout(
        () =>
          setEffectStyle(
            'bg-white w-[360px] rounded-xl py-10 flex flex-col justify-center items-center scale',
          ),
        100,
      );
    }
  }, [pathname]);

  return (
    <div
      className="fixed w-[100%] h-[100vh] bg-[#00000025] z-[200] flex justify-center items-center scale"
      onClick={closeLoginModal}
      data-name="background"
    >
      <div className={effectStyle}>
        <img src={logo} className="w-[180px]" />

        {localStorage.login === 'false' ? (
          <>
            <div className="my-2 text-xs text-gray-800">
              {t('sign_in_now_and_use_solink')}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center items-center text-sm w-[320px] mt-3"
            >
              <input
                name="email"
                type="text"
                placeholder={t('email')}
                required
                ref={emailRef}
                className="p-3 my-1 w-[90%] border"
              />
              <input
                name="password"
                type="password"
                placeholder={t('password')}
                required
                ref={passwordRef}
                className="p-3 my-1 w-[90%] border"
              />
              <input
                type="submit"
                value={t('sign_in')}
                className="bg-[#08B295] text-white w-[90%] rounded-full p-2 hover:cursor-pointer text-sm m-2"
              />
              {/* <button
              onClick={handleGoogleLogIn}
              className="bg-gray-200 w-[100%] rounded-full p-2 hover:cursor-pointer text-sm flex items-center justify-center m-2 mt-1"
            >
              {newAccount ? "구글 계정으로 회원가입" : "구글 계정으로 로그인"}
            </button> */}
            </form>
            <span
              onClick={handleNewAccount}
              className="m-2 text-xs underline underline-offset-2 hover:cursor-pointer"
            >
              {t('are_you_not_a_member_yet')}
            </span>
            <div className="text-xs mt-5 mb-2 text-gray-800 border-t w-[90%] text-center pt-6">
              {t('start_in_3_seconds_with_simple_sign_in')}
            </div>
            <img
              src={googlelogo}
              className="w-[160px] my-1 hover:cursor-pointer"
              alt="google"
              onClick={handleGoogleLogIn}
            />
          </>
        ) : (
          <>
            <div className="my-6 text-sm text-center text-gray-800">
              <div>{t('this_account_has_not_been_authenticated_by_email')}</div>
              <div>
                {t('please_authenticate_your_email_to_use_the_service')}
              </div>
            </div>
            <Link
              to="/verification"
              className="bg-[#08B295] px-3 py-2 rounded-xl text-sm text-white"
            >
              {t('authenticate')}
            </Link>
          </>
        )}
      </div>
      {/* <div className="flex flex-col justify-center items-center bg-white p-9 rounded-2xl w-[320px]"> */}

      {/* </div> */}
    </div>
  );
}
