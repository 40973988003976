import styled from 'styled-components';

export const IssueWrapper = styled.div`
  width: 100%;
  min-height: 1318px;
  padding-top: 164px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  min-width: 350px;
  overflow: hidden;
`;
export const IssueTitle = styled.p`
  color: #0d0d0d;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
`;

// export const IssueContentVertical = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 50px;
//   @media (max-width: 1380px) {
//     gap: 20px;
//   }
// `;
export const IssueContentDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  justify-content: center;
  padding: 30px 20px;
  border: 1px solid #cacaca;
  width: 412px;
  border-radius: 10px;
  @media (max-width: 1380px) {
    width: 318px;
    padding: 23px 15px;
  }
`;
export const IssueContentIncludeImg = styled.div`
  display: flex;
  flex-direction: column;
  width: 412px;
  border-radius: 10px;
  border: 1px solid #cacaca;
  img {
    width: 100%;
    height: 227px;
    border-radius: 10px 10px 0 0;
  }
  ${IssueContentDesc} {
    border-radius: 0 0 10px 10px;
    border: none;
  }
  @media (max-width: 1380px) {
    width: 318px;
  }
`;

export const IssueContentDisplay = styled.div`
  display: flex;
  max-width: 1500px;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  ${IssueContentIncludeImg} {
    flex: 0 0 auto;
  }
`;

export const IssueContentTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  white-space: pre-wrap;
  color: #212121;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  @media (max-width: 1380px) {
    font-size: 18px;
  }
`;
export const IssueContentDate = styled.p`
  color: #4d4d4d;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  @media (max-width: 1380px) {
    font-size: 12px;
  }
`;
export const IssueContentBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  gap: 12px;
  span {
    border: 1px solid #838383;
    border-radius: 200px;
    padding: 6px 14px;
    color: #4d4d4d;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const IssueContentExpandBtn = styled.button`
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 26px 32px;
  color: #fff;
  font-weight: 700;
  font-family: 'Pretendard';
  border-radius: 50px;
  background: linear-gradient(127deg, #1bba9c 9.35%, #21a9d3 91.16%);
  @media (max-width: 500px) {
    width: 220px;
    border-radius: 30px;
    font-size: 18px;
    gap: 5px;
    padding: 11px;
  }
`;

export const IssueContentNewsLinkBtn = styled.div`
  cursor: pointer;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 26px 32px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  font-family: 'Pretendard';
  border-radius: 50px;
  background: linear-gradient(127deg, #1bba9c 9.35%, #21a9d3 91.16%);
  @media (max-width: 500px) {
    width: 220px;
    border-radius: 30px;
    font-size: 18px;
    gap: 5px;
    padding: 11px;
  }
`;
