import styled from "styled-components";
import '../../assets/introduce/pretendardvariable.css';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`
// ** Top Button
export const GoTopBtn = styled.button`
  width: 70px;
  height: 70px;
  border-radius: 70px;
  background-color: gray;
  color: #fff;
  font-family: 'Pretendard';
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none ;
  cursor: pointer;
  z-index: 100;
  @media (max-width: 700px) {
    display: none;
  }
`

// ** TopNavigation.tsx =====
export const NavWrapper = styled.div`
  width: 100%;
  height: 82px;
  padding: 0 100px;
  background: #111;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  transition: all.7s;
  min-width: 350px;
  @media (max-width: 1200px) {
    padding: 0 50px;
  }
  @media (max-width: 880px) {
    padding: 0 20px;
  }
`
export const NavLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  width: 90px;
  height: 63px;
`
export const NavLogo = styled.img`
  width: 100%;
  height: 100%;
`
export const NavRight = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
  ${Icon} {
    display: none;
    width: 28px;
    height: 28px;
    color: #fff;
    scale: 2;
  }
  @media (max-width: 1200px) {
    gap: 40px;
  }
  @media (max-width: 1024px) {
    gap: 20px;
  }
  @media (max-width: 500px) {
    ${Icon} {
      display: flex;
    }
  }
`
export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  @media (max-width: 1200px) {
    gap: 16px;
  }
  @media (max-width: 1024px) {
    gap: 8px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`
export const NavLink = styled.button`
  border: none;
  background-color: transparent;
  color: #fff;
  font-family: 'Pretendard';
  cursor: pointer;
  padding: 10px 24px;
  font-size: 18px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 800px) {
    padding: 10px 12px;
  }
`
export const NavBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0px;
    left: 0;
    background: rgba(0, 0, 0, 0.20);
    width: 100%;
    padding: 8px 16px;
    padding-bottom: 18px;
  }
`
export const NavDemoBtn = styled.button`
  font-family: 'Pretendard';
  color: #111;
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: all.3s;
  border: 2px solid rgba(27, 186, 156, 0.50);
  background: rgba(27, 186, 156, 0.50);
  box-shadow: -4px -4px 8px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  &:hover {
    color: #21A188;
    background-color: #fff;
  }
  @media (max-width: 1024px) {
    padding: 9px 18px;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    background: linear-gradient(127deg, #1BBA9C 9.35%, #21A9D3 91.16%);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15), -4px -4px 8px 0px rgba(255, 255, 255, 0.10);
    padding: 12px 24px;
    font-size: 16px;
    width: 214px;
  }
`
export const NavLinkBtn = styled.button`
  font-family: 'Pretendard';
  cursor: pointer;
  padding: 12px 24px;
  font-size: 15px;
  font-weight: 800;
  color: #111;
  transition: all.3s;
  border-radius: 12px;
  border: 2px solid var(--Whites-White_015, rgba(255, 255, 255, 0.15));
  background: rgba(255, 255, 255, 0.50);
  box-shadow: -4px -4px 8px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.20);
  &:hover {
    color: #fff;
    background-color: #111;
  }
  @media (max-width: 1024px) {
    padding: 9px 18px;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    background: #fff;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15), -4px -4px 8px 0px rgba(255, 255, 255, 0.10);
    padding: 12px 24px;
    font-size: 15px;
    width: 102px;
  }
`
export const MobileMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  transition: all.2s;
  ${NavLink} {
    font-size: 32px;
  }
  ${Icon} {
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #fff;
  }
`
export const MobileMenuWrapperBlack = styled.div`
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`
export const IsDemoWrapper = styled.div`
  width: 580px;
  height: 420px;
  border: 1px solid #F3F8FC;
  border-radius: 16px;
  display: flex;
  background-color: #F3F8FC;
  position: fixed;
  top: 90px;
  right: 100px;
  z-index: 1000;
  min-width: 350px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    background-color: transparent;
    border: none;
    gap: 16px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    justify-content: center;
    align-items: center;
    border-radius: none;
    width: auto;
  }
`
export const IsDemoImg = styled.img`
  object-fit: contain;
  margin: auto 0;
`
export const IsDemoSI = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-right: 1px solid #E0E0E0;
  padding: 37px 42px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    background-color: #F3F8FC;
    border: none;
    padding: 16px 20px;
    width: 600px;
    border-radius: 12px !important;
  }
  @media (max-width: 620px) {
    width: 400px;
  }
  @media (max-width: 420px) {
    width: 300px;
  }
`
export const IsDemoNormal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 37px 42px;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    background-color: #F3F8FC;
    border: none;
    border-radius: 12px !important;
    padding: 16px 20px;
    width: 600px;
  }
  @media (max-width: 620px) {
    width: 400px;
  }
  @media (max-width: 420px) {
    width: 300px;
  }
`
export const IsDemoTitle = styled.p`
  color: #111;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
`
export const IsDemoContent = styled.p`
  color: #555;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`
export const IsDemoRequestBtn = styled.button`
  width: 124px;
  font-family: 'Pretendard';
  padding: 12px 24px;
  border-radius: 12px;
  background: linear-gradient(127deg, #1BBA9C 9.35%, #21A9D3 91.16%);
  box-shadow: -4px -4px 8px 0px rgba(255, 255, 255, 0.10), 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: -0.45px;
  border: none;
  cursor: pointer;
`

// ** Polygon.tsx =====
export const PolygonWrapper = styled.div`
  width: 100vw;
  height: 200px;
  background-color: #fff;
  @media (max-width: 1200px) {
    height: 150px;
  }
  @media (max-width: 800px) {
    height: 100px;
  }
  @media (max-width: 450px) {
    height: 58px;
  }
`
export const PolygonFigure = styled.div`
  width: 0;
  height: 0;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  border-top: 200px solid #F7F6FB;
  @media (max-width: 1200px) {
    border-top: 150px solid #F7F6FB;
  }
  @media (max-width: 800px) {
    border-top: 100px solid #F7F6FB;
  }
  @media (max-width: 450px) {
    border-top: 58px solid #F7F6FB;
  }
`;

// ** ImgSlider.tsx =====
export const RobotSlider = styled.div`
  margin-top: 123px;
  width: 1520px;
  height: 426px;
  vertical-align: middle;
  position: relative;
  transform: translateX(-10px);
  min-width: 350px;
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      transform: translateX(50px);
      @media (max-width: 1080px) {
        transform: translateX(80px);
      }
      @media (max-width: 840px) {
        transform: translateX(40px);
      }
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          > div {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .slick-prev, .slick-next {
      background-color: red;
    }
  }
  @media (max-width: 1600px) {
    width: 1300px;
  }
  @media (max-width: 1400px) {
    width: 1000px;
  }
  @media (max-width: 1080px) {
    width: 750px;
  }
  @media (max-width: 840px) {
    width: 550px;
  }
  @media (max-width: 570px) {
    width: 430px;
  }
  @media (max-width: 550px) {
    margin-top: -80px;
  }
  @media (max-width: 450px) {
    width: 380px;
  }
`
export const RobotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
export const ActiveRobotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  border: 1px solid red;
  transform: translate(-96px, -10px);
  border-radius: 400px;
  border: 0.84px solid #EEE;
  background: #F1FBF9;
  position: relative;
  @media (max-width: 1600px) {
    width: 300px;
    height: 300px;
    transform: translate(-48px, -10px);
  }
  @media (max-width: 1400px) {
    width: 240px;
    height: 240px;
    transform: translate(-48px, -10px);
  }
  @media (max-width: 840px) {
    width: 180px;
    height: 180px;
    transform: translate(-38px, -10px);
  }
  @media (max-width: 570px) {
    width: 130px;
    height: 130px;
    transform: translate(-28px, -10px);
  }
`
export const ActionRobotDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  // background-color: rgba(255, 255, 255, 0.50);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  z-index: -1;
  h1 {
    color: #111;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
  }
  h3 {
    color: #555;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  @media (max-width: 1600px) {
    height: 60px;
  }
  @media (max-width: 1400px) {
    height: 40px;
  }
  @media (max-width: 840px) {
    height: 40px;
    gap: 4px;
    bottom: -10px;
    h1 {
      font-size: 18px;
    }
    h3 {
      font-size: 13px;
    }
  }
  @media (max-width: 570px) {
    bottom: -20px;
    h1 {
      font-size: 14px;
    }
    h3 {
      font-size: 10px;
    }
  }
`
export const ActiveRobot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 265px;
  height: 265px;
  opacity: 1;
  img {
    width: 100%;
    height: 225px;
    object-fit: contain;
  }
  @media (max-width: 1600px) {
    width: 200px;
    height: 200px;
    img {
      height: 200px;
    }
  }
  @media (max-width: 1400px) {
    width: 160px;
    height: 160px;
    img {
      height: 160px;
    }
  }
  @media (max-width: 840px) {
    width: 130px;
    height: 130px;
    img {
      height: 130px;
    }
  }
  @media (max-width: 570px) {
    width: 108px;
    height: 108px;
    img {
      height: 108px;
    }
  }
`
export const NonActiveRobot = styled.div`
  height: 207px;
  width: 207px;
  opacity: 0.5;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 1600px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 1400px) {
    width: 110px;
    height: 110px;
  }
  @media (max-width: 840px) {
    width: 90px;
    height: 90px;
  }
  @media (max-width: 570px) {
    width: 74px;
    height: 74px;
  }
`
export const ArrowBtn = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 52px;
  background-color: #f5f5f5;
  border: 0.84px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  &.prev {
    left: -20px;
    top: 50%;
    z-index: 99;
  }
  &.next {
    right: -20px;
    top: 50%;
    z-index: 99;
  }
  @media (max-width: 800px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 650px) {
    width: 24px;
    height: 24px;
    > * {
      scale: 0.7;
    }
    &.prev {
      left: 20px;
    }
    &.next {
      right: 10px;
    }
  }
  @media (max-width: 650px) {
    &.prev {
      left: 20px;
    }
    &.next {
      right: 0px;
    }
  }
  @media (max-width: 370px) {
    &.prev {
      left: 40px;
    }
    &.next {
      right: 10px;
    }
  }
`