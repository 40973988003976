import React, { ChangeEvent, useEffect, useState } from 'react';
import '../style/animation.css';

import RobotTable from '../components/robot/RobotTable';
import dayjs from 'dayjs';
import { DeviceDataType, FirestoreCollenctions } from '../_types';
import Modal from '../components/modal/Modal';
import ModalPortal from '../components/modal/ModalPortal';
import useRobotstore from '../store/robotStore';
import { useTranslation } from 'react-i18next';
import useUserStore from '../store/userStore';

import useLoginStore from '../store/loginStore';
import { AuthType, Permission } from '../_types/user';
import { SiteType } from '../_types/site';
import { getUserById } from '../_api/firestore/user';

import useRobotData from '../hooks/useRobotData';
import useAuth from '../hooks/useAuth';
import InquiryModal from '../components/modal/InquiryModal';
import { useSearchParams } from 'react-router-dom';
import { getAllSiteListOnSnapshot } from '../_api/firestore/site';
import Detail from '../components/robot/Detail';
import MultiRobotDetail from '../components/robot/MultiRobotDetail';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import SearchInput from '../components/common/SearchInput';
import useErrorStore from '../store/errorStore';
import { Tabs } from 'antd';
import MultiRobotWork from '../components/robot/MultiRobotWork';

export default function RobotPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const { hasAuthSite, hasAuthRobot, login } = useAuth();

  // 전역 상태
  const { userInfo } = useLoginStore();
  const {
    isShowRobotDetail,
    isShowMultiRobotDetail,
    updateIsShowRobotDetail,
    updateIsShowMultiRobotDetail,
    selectedRobot,
  } = useRobotstore();
  const { userStatus, updateIsShowUserIcon } = useUserStore();
  const { robotDetailError, updateRobotDetailError } = useErrorStore();
  // 로봇, 사이트
  const [userData, setUserData] = useState<AuthType | null>(null);
  const [siteList, setSiteList] = useState<SiteType[]>([]);
  const [filterSiteList, setfilterSiteList] = useState<
    { name: string; id: string }[]
  >([]);

  const { socketData, syncRobotList } = useRobotData(userData);

  // 필터
  const [selectedSite, setSelectedSite] = useState<string>('');
  const [selectRobotType, setSelectRobotType] = useState<string>('');
  const [robotTypeFilter, setRobotTypeFilter] = useState<string[]>([]);
  const [selectRobotName, setSelectRobotName] = useState('');

  // 지도에서 로봇 운영 클릭 시
  useEffect(() => {
    if (!searchParams.get('siteName') || !siteList) return;

    const siteName = searchParams.get('siteName');
    const site = siteList.find((item) => item.name === siteName);
    if (!site || !site.id) return;

    setSelectedSite(site.id);
    setSearchParams('');
  }, [searchParams, siteList, setSearchParams, syncRobotList]);

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    if (!userData) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      getAllSiteListOnSnapshot(setSiteList);
      return;
    }

    const fetchData = async () => {
      const siteData: SiteType[] = [];
      // TODO: 공통함수 빼기
      userData.auth_sites.map(async (siteId) => {
        const docRef = doc(db, FirestoreCollenctions.SITES, siteId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = {
            id: docSnapshot.id,
            ...(docSnapshot.data() as SiteType),
          };

          siteData.push(data);
        } else {
          return null;
        }

        setSiteList(siteData as SiteType[]);
      });
    };

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      fetchData();
    }
  }, [userData, userStatus]);

  useEffect(() => {
    if (socketData.length > 0) {
      const temp2: string[] = [];

      const temp = socketData.map((item: any) => item.modelType);

      const map = new Map();

      for (const item of temp) {
        if (!map.has(item)) {
          map.set(item, true);
          temp2.push(item);
        }
      }

      setRobotTypeFilter(temp2);
    }
  }, [socketData]);

  useEffect(() => {
    setfilterSiteList(
      siteList.map((item) => ({ name: item.name, id: item.id || '' })),
    );
  }, [siteList]);

  const handleSelectType = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    setSelectRobotType(target.value);
  };

  const handleChange = (
    setValue: (value: string) => void,
    item?: { name: string; id: string },
  ) => {
    if (item) {
      setValue(item.name);
      setSelectedSite(item.id);
      return;
    }
    setValue('사이트 전체');
    setSelectedSite('');
  };

  const tabItems = [
    {
      key: '1',
      label: t('robot'),
      children: (
        <>
          <section
            className="flex items-center justify-between gap-5 pb-3"
            data-name="bg"
          >
            <>
              <SearchInput
                siteList={filterSiteList}
                handleChange={handleChange}
                setSelectedSite={setSelectedSite}
                addClassName={'border-none'}
              />

              <select
                className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px]   text-sm border border-white rounded-lg shadow-sm"
                value={selectRobotType}
                onChange={handleSelectType}
                data-name="robotType"
              >
                <option hidden>로봇 타입 선택</option>
                <option value="">{t('robot_types_all')}</option>
                {robotTypeFilter.length !== 0 &&
                  robotTypeFilter.map((item: string, index: number) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>

              <input
                className="w-full min-w-[130px] max-w-[200px] px-3 py-2  text-sm border border-white rounded-lg shadow-sm"
                type="text"
                placeholder={t('robot_name_searching')}
                value={selectRobotName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  setSelectRobotName(value);
                }}
              />
              <div className="flex justify-end w-full gap-2 pr-10 text-xs">
                <span>{t('last_update')} </span>
                <span>{dayjs(new Date()).format('MM-DD HH:mm:ss')}</span>
              </div>
            </>
          </section>
          <RobotTable
            selectRobotType={selectRobotType}
            selectRobotName={selectRobotName}
            selectedSite={selectedSite}
            siteList={siteList as SiteType[]}
            syncRobotList={syncRobotList as DeviceDataType[]}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('Multirobot_Work'),
      children: <MultiRobotWork />,
    },
  ];

  return (
    <div
      className="w-full pl-[75px] flex flex-col bg-neutral-50 h-screen pb-2"
      // onClick={(event: React.MouseEvent<HTMLElement>) => {
      //   const target = event.target as HTMLDivElement;
      //   const name = target.parentElement?.dataset.name;
      //   if (isShowRobotDetail && name !== 'list') {
      //     updateIsShowRobotDetail(false);
      //   }
      // }}
    >
      <div className="fixed top-0 z-20 w-full pt-10 pb-3 pl-12 text-2xl text-gray-700 bg-neutral-50">
        {t('robot_operation')}
      </div>

      <section className="pt-[91px] w-full px-12">
        <Tabs items={tabItems} />
      </section>

      {isShowRobotDetail && (
        <div className="w-[100%] z-30 h-[100vh] fixed">
          <div
            onClick={() => {
              updateIsShowRobotDetail(false);
              robotDetailError && window.location.reload();
              updateRobotDetailError(false);
            }}
            className="fixed w-[100%] h-[100vh] bg-black opacity-[0.15]"
          ></div>
        </div>
      )}
      <Detail
        command={
          syncRobotList?.find((r) => r.deviceId === selectedRobot)
            ?.robotReference
        }
      />

      {isShowMultiRobotDetail && (
        <div className="w-[100%] z-30 h-[100vh] fixed">
          <div
            onClick={() => {
              updateIsShowMultiRobotDetail(false);
              // robotDetailError && window.location.reload();
              // updateRobotDetailError(false);
            }}
            className="fixed w-[100%] h-[100vh] bg-black opacity-[0.15]"
          ></div>
        </div>
      )}
      <MultiRobotDetail />

      <ModalPortal>
        <Modal />
      </ModalPortal>
      {(hasAuthSite === 'false' || hasAuthRobot === 'false') &&
        !(login === 'false') && <InquiryModal />}
    </div>
  );
}
