import React, { useEffect, useState } from 'react';
import {
  IssueContentNewsLinkBtn,
  IssueContentExpandBtn,
  IssueContentBadge,
  IssueContentDate,
  IssueContentDesc,
  IssueContentDisplay,
  IssueContentIncludeImg,
  IssueContentTitle,
  IssueTitle,
  IssueWrapper,
} from './styles';
import { CiShare1 } from 'react-icons/ci';
import { Icon } from '../../pages/IntroducePage';
import { getNewsData } from '../../_api/firestore/news';
import { newsType } from '../../_types/news';

export const Issue = () => {
  const MAX_SHOW_COUNT = 6;
  const MIN_SHOW_COUNT = 2;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showCount, setShowCount] = useState(0);
  const [range, setRange] = useState('MAX');
  const [newsData, setNewsData] = useState<newsType[]>([]);

  useEffect(() => {
    setShowCount(windowWidth > 993 ? MAX_SHOW_COUNT : MIN_SHOW_COUNT);
  }, [windowWidth]);

  useEffect(() => {
    const newRange = windowWidth <= 993 ? 'MIN' : 'MAX';
    if (newRange === range) return;

    setRange(newRange);

    newRange === 'MAX'
      ? setShowCount(MAX_SHOW_COUNT)
      : setShowCount(MIN_SHOW_COUNT);
  }, [windowWidth, range]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleExpandBtn = () => {
    setShowCount((prev) => prev + 2);
  };

  useEffect(() => {
    (async () => {
      const newsData: newsType[] = await getNewsData();
      setNewsData(newsData);
    })();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return newsData.length >= 6 ? (
    <IssueWrapper>
      <IssueTitle>SOLlink 소식</IssueTitle>
      <IssueContentDisplay>
        {newsData.slice(0, showCount).map((news: newsType, idx) => (
          <a href={news.link} target="_blank" key={idx}>
            <IssueContentIncludeImg>
              <img
                src={
                  news.thumbnail
                    ? `https://${process.env.REACT_APP_PUBLIC_S3_BUCKET}.s3.${process.env.REACT_APP_PUBLIC_S3_BUCKET_REGION}.amazonaws.com/${news.thumbnail}`
                    : '/image_og.png'
                }
              />
              <IssueContentDesc>
                <IssueContentTitle>{news.title}</IssueContentTitle>
                <IssueContentDate>{news.date}</IssueContentDate>
                <IssueContentBadge>
                  {Array.isArray(news.tags) &&
                    news.tags?.map((tag: string) => (
                      <span key={tag}>{tag}</span>
                    ))}
                </IssueContentBadge>
              </IssueContentDesc>
            </IssueContentIncludeImg>
          </a>
        ))}
      </IssueContentDisplay>
      {showCount === MAX_SHOW_COUNT ? (
        <IssueContentNewsLinkBtn
          onClick={() =>
            window.open(
              'https://www.myrobotsolution.com/contents/marosol-story?tab=news',
            )
          }
        >
          솔링크 보도자료 바로가기
          <Icon>
            <CiShare1 />
          </Icon>
        </IssueContentNewsLinkBtn>
      ) : (
        <IssueContentExpandBtn onClick={handleExpandBtn}>
          더보기
        </IssueContentExpandBtn>
      )}
    </IssueWrapper>
  ) : null;
};

export default React.memo(Issue);
