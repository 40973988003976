import React, { useEffect, useState } from 'react'
import { Icon, LinkWrapper, MobileMenuWrapper, MobileMenuWrapperBlack, NavBtns, NavLinkBtn, NavDemoBtn, NavLink, NavLogo, NavLogoWrapper, NavRight, NavWrapper } from './styles'
import Logo from '../../assets/introduce/Image/Logo.png'
import { RiMenu2Fill } from "react-icons/ri";
import {Link} from "react-router-dom";


export const TopNavigation = ({ setIsDemo }: { setIsDemo: Function }) => {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToSoLlink = () => {
    window.scrollTo({
      top: 1050,
      behavior: 'smooth'
    });
  };
  const scrollToSolution = () => {
    window.scrollTo({
      top: (windowWidth >= 449) ?
          ((windowWidth >= 500) ?
              ((windowWidth >= 550) ?
                  ((windowWidth >= 590) ?
                      ((windowWidth >= 650) ?
                          ((windowWidth >= 800) ?
                              ((windowWidth >= 1200) ?
                                  ((windowWidth >= 1400) ? 3900
                                      : 3900)
                                  : 3840)
                              : 4340)
                          : 4220)
                      : 4220)
                  : 4220)
              : 3740)
          : 3340,
      behavior: 'smooth'
    });
  };
  const scrollToExpansion = () => {
    window.scrollTo({
      top: (windowWidth >= 449) ?
          ((windowWidth >= 500) ?
              ((windowWidth >= 550) ?
                  ((windowWidth >= 590) ?
                      ((windowWidth >= 650) ?
                          ((windowWidth >= 800) ?
                              ((windowWidth >= 1200) ?
                                  ((windowWidth >= 1400) ? 9160
                                      : 8800)
                                  : 8500)
                              : 9000)
                          : 9480)
                      : 8900)
                  : 8200)
              : 7500)
          : 6700,
      behavior: 'smooth'
    });
  };
  const scrollToMap = () => {
    window.scrollTo({
      top: (windowWidth >= 449) ?
          ((windowWidth >= 500) ?
              ((windowWidth >= 550) ?
                  ((windowWidth >= 590) ?
                      ((windowWidth >= 650) ?
                          ((windowWidth >= 800) ?
                              ((windowWidth >= 1200) ?
                                  ((windowWidth >= 1400) ? 10650
                                      : 10380)
                                  : 9880)
                              : 10380)
                          : 10680)
                      : 10080)
                  : 9280)
              : 8580)
          : 7680,
      behavior: 'smooth'
    });
  };

  const linkToDevelopers = () => {
    window.open('https://bigwaverobotics.notion.site/SOLlink-Hub-474397c99ad946e6b675325da04c7520?pvs=4', '_blank');
  }

  return (
      <NavWrapper>
        <NavLogoWrapper>
          <NavLogo src={Logo} />
        </NavLogoWrapper>
        <NavRight>
          <LinkWrapper>
            <NavLink onClick={scrollToSoLlink}>SOLlink란?</NavLink>
            <NavLink onClick={scrollToSolution}>솔루션</NavLink>
            <NavLink onClick={scrollToExpansion}>기대효과</NavLink>
            <NavLink onClick={scrollToMap}>이용현황</NavLink>
            <NavLink onClick={linkToDevelopers}>Developers</NavLink>
          </LinkWrapper>
          <Icon onClick={() => setOpen(true)}><RiMenu2Fill /></Icon>
          <NavBtns>
            <NavDemoBtn onClick={() => setIsDemo(true)}>
              데모신청하기
            </NavDemoBtn>
            <Link to={"/total"}><NavLinkBtn style={{display: windowWidth >= 768 ? 'block' : 'none'}}>콘솔</NavLinkBtn></Link>
          </NavBtns>
        </NavRight>
        {open && (
            <MobileMenuWrapper>
              <MobileMenuWrapperBlack onClick={() => setOpen(false)} />
              <NavLink onClick={scrollToSoLlink} style={{zIndex: 1000}}>SOLlink란?</NavLink>
              <NavLink onClick={scrollToSolution} style={{zIndex: 1000}}>솔루션</NavLink>
              <NavLink onClick={scrollToExpansion} style={{zIndex: 1000}}>기대효과</NavLink>
              <NavLink onClick={scrollToMap} style={{zIndex: 1000}}>이용현황</NavLink>
              <NavLink onClick={linkToDevelopers} style={{zIndex: 1000}}>Developers</NavLink>
            </MobileMenuWrapper>
        )}
      </NavWrapper>
  )
}

export default React.memo(TopNavigation)