import { create } from 'zustand';

interface RobotStore {
  selectedRobotDetailData: any;
  selectedTaskDetailData: any;
  isShowRobotDetail: boolean;
  isShowMultiRobotDetail: boolean;
  selectedRobot: string | null;
  selectedTask: number | null;
  changeSelectedRobotDetailData: (inputData: any) => void;
  changeSelectedTaskDetailData: (inputData: any) => void;
  changeSelectedRobot: (input: any) => void;
  changeSelectedTask: (input: any) => void;
  updateIsShowRobotDetail: (input: any) => void;
  updateIsShowMultiRobotDetail: (input: any) => void;
}

const useRobotstore = create<RobotStore>()((set) => ({
  selectedRobotDetailData: null,
  selectedTaskDetailData: null,
  isShowRobotDetail: false,
  isShowMultiRobotDetail: false,
  selectedRobot: null,
  selectedTask: null,
  changeSelectedRobotDetailData: (inputData) =>
    set((state) => ({
      selectedRobotDetailData: inputData,
    })),
  changeSelectedTaskDetailData: (inputData) =>
    set((state) => ({
      selectedTaskDetailData: inputData,
    })),
  changeSelectedRobot: (input) =>
    set((state) => ({
      selectedRobot: input,
    })),
  changeSelectedTask: (input) =>
    set((state) => ({
      selectedTask: input,
    })),
  updateIsShowRobotDetail: (input) =>
    set((state) => ({
      isShowRobotDetail: input,
    })),
  updateIsShowMultiRobotDetail: (input) =>
    set((state) => ({
      isShowMultiRobotDetail: input,
    })),
}));

export default useRobotstore;
