import React, { useEffect, useState } from "react";
import { GrSchedules } from "react-icons/gr";
import { BsDot } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Select,
  Switch,
  TimePicker,
} from "antd";
import "../../style/schedule.css";
import { MdSaveAs, MdEdit } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import SettingWorkModal from "./SettingWorkModal";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
import useLoginStore from "../../store/loginStore";
import useUserStore from "../../store/userStore";
import useScheduleStore from "../../store/scheduleStore";
import useScheduleData from "../../hooks/useScheduleData";
import useRestRobotData from "../../hooks/useRestRobotData";
import { AuthType, FilterSiteType } from "../../_types";
import { getUserById } from "../../_api/firestore/user";
import { getCmdList } from "../../_api/rest/getAPI";

const style = {
  titleStyle: {
    borderBottom: "1px solid #ddd",
    paddingBottom: "10px",
  },

  subTitleStyle: {
    display: "flex",
    marginTop: "25px",
    height: "34px",
  },

  textStyle: {
    fontSize: "20px",
    alignSelf: "center",
    fontWeight: "bold",
    paddingLeft: "10px",
  },

  subTextStyle: {
    alignSelf: "center",
    fontWeight: "bold",
  },

  alignCenter: {
    alignSelf: "center",
  },
};

const optionsList = [
  { label: "월", value: "MON" },
  { label: "화", value: "TUE" },
  { label: "수", value: "WED" },
  { label: "목", value: "THU" },
  { label: "금", value: "FRI" },
  { label: "토", value: "SAT" },
  { label: "일", value: "SUN" },
];

interface PropsType {
  siteList: FilterSiteType[];
}

const SettingSchedule = ({ siteList }: PropsType) => {
  const {
    scheduleData,
    fetchScheduleData,
    saveScheduleData,
    editScheduleData,
    removeScheduleData,
  } = useScheduleData();
  const { selectedUserAuthSite } = useUserStore();
  const { userInfo } = useLoginStore();
  const {
    scheduleId,
    deviceId,
    robotCommand,
    workStartDate,
    updateScheduleId,
    updateDeviceId,
    updateRobotCommand,
  } = useScheduleStore();
  const [userData, setUserData] = useState<AuthType | null>(null);
  const {
    robotData,
    fetchSiteRobots,
    robotFilterData,
    selectedSiteRobotData,
    findRobotByDeviceId,
  } = useRestRobotData(userData);

  const [optionSiteList, setOptionSiteList] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [selectSite, setSelectSite] = useState<string | null>(null);
  const [selectRobot, setSelectRobot] = useState<any>({});
  const [selectWork, setSelectWork] = useState<any>({});
  const [workName, setWorkName] = useState<string>("");

  const [useRepeat, setUseRepeat] = useState<boolean>(false);
  const [repeatDay, setRepeatDay] = useState<string[]>([]);
  const [repeatAll, setRepeatAll] = useState<boolean>(false);

  const [repeatDates, setRepeatDates] = useState<string[]>([]);
  const [workDate, setWorkDate] = useState<Dayjs | null>(null);
  const [workStartTime, setWorkStartTime] = useState<Dayjs | null>(null);
  const [workEndDate, setWorkEndDate] = useState<Dayjs | null>(null);

  /* 일자, 시간 string 값 */

  const [workDateStr, setWorkDateStr] = useState<string | string[] | null>(
    null
  );
  const [workStartTimeStr, setWorkStartTimeStr] = useState<
    string | string[] | null
  >(null);
  const [workEndDateStr, setWorkEndDateStr] = useState<
    string | string[] | null
  >("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [disableStatus, setDisableStatus] = useState<boolean>(true);

  const [selectSiteValid, setSelectSiteValid] = useState<any>();
  const [selectRobotValid, setSelectRobotValid] = useState<any>();
  const [selectWorkValid, setSelectWorkValid] = useState<any>();
  const [workDateValid, setWorkDateValid] = useState<any>();
  const [workStartTimeValid, setWorkStartTimeValid] = useState<any>();
  const [repeatDayValid, setRepeatDayValid] = useState<any>();
  const [workEndDateValid, setWorkEndDateValid] = useState<any>();

  const [cmdList, setCmdList] = useState<any[]>([]);

  useEffect(() => {
    getCmdList(deviceId as string).then((res) => setCmdList(res as any));
  }, [selectWork]);

  useEffect(() => {
    if (!cmdList || cmdList.length === 0) return;

    setWorkName(
      `${mapCommandType[selectWork.command] || ""}${
        getWorkName(selectWork) ? `(${getWorkName(selectWork)})` : ""
      }`
    );
  }, [cmdList]);

  useEffect(() => {
    if (workStartDate) {
      onScheduleReset();
      setWorkDate(dayjs(workStartDate, "YYYY-MM-DD"));
      setWorkDateStr(workStartDate);
    }
  }, [workStartDate]);

  useEffect(() => {
    setOptionSiteList(
      siteList.map((item: any) => {
        const newItem: any = {};
        newItem.label = item.name;
        newItem.value = item.id;
        return newItem;
      })
    );
  }, [siteList]);

  useEffect(() => {
    if (!userInfo) return;
    const fetchData = async () => {
      const data = await getUserById(userInfo.uid);
      setUserData(data);
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    scheduleId && fetchScheduleData(scheduleId);
  }, [scheduleId]);

  useEffect(() => {
    if (deviceId !== null && robotCommand !== null) {
      const selectedRobot = findRobotByDeviceId(deviceId as string);
      setSelectRobot(selectedRobot);
      setSelectWork(robotCommand);
    }
  }, [deviceId, robotCommand, robotData.length]);

  useEffect(() => {
    selectedSiteRobotData(selectSite as string);
  }, [selectSite]);

  useEffect(() => {
    if (workDate && workEndDate) {
      let currentDate = dayjs(workDate);
      const repeatDates = [];

      while (currentDate.isSameOrBefore(workEndDate)) {
        if (repeatDay.includes(currentDate.format("ddd").toUpperCase())) {
          repeatDates.push(currentDate.format("YYYY-MM-DD"));
        }
        currentDate = currentDate.add(1, "day");
      }

      setRepeatDates(repeatDates);
    }
  }, [workDate, workEndDate, repeatDay]);

  useEffect(() => {
    if (scheduleData) {
      const {
        siteId,
        startDate,
        startTime,
        endDate,
        isRepeated,
        repeatedDays,
      } = scheduleData;
      setSelectSite(siteId);

      setWorkDateStr(startDate);
      setWorkDate(dayjs(startDate, "YYYY-MM-DD"));

      setWorkStartTimeStr(startTime);
      setWorkStartTime(dayjs(startTime, "HH:mm:ss"));

      if (isRepeated) {
        setDisableStatus(false);

        setUseRepeat(true);
        setWorkEndDateStr(endDate);
        setWorkEndDate(dayjs(endDate, "YYYY-MM-DD"));

        setRepeatDay(repeatedDays);
        repeatedDays.length === 7 && setRepeatAll(true);
      } else {
        setDisableStatus(true);

        setUseRepeat(false);
        setWorkEndDateStr("");
        setWorkEndDate(null);

        setRepeatDay([]);
        setRepeatDates([]);
        setRepeatAll(false);
      }

      setSelectSiteValid("");
      setSelectRobotValid("");
      setSelectWorkValid("");
      setWorkDateValid("");
      setWorkStartTimeValid("");
      setRepeatDayValid("");
      setWorkEndDateValid("");
    }
  }, [scheduleData]);

  useEffect(() => {
    onScheduleReset();

    if (selectedUserAuthSite.length === 1) {
      setSelectSite(selectedUserAuthSite[0]);
    }
  }, [selectedUserAuthSite.length]);

  useEffect(() => {
    if (selectRobot && Object.keys(selectRobot).length > 0) {
      setSelectRobotValid("");
    }
    if (selectWork && Object.keys(selectWork).length > 0) {
      setSelectWorkValid("");
    }
  }, [selectRobot, selectWork]);

  const mapCommandType: any = {
    COMMAND_STOP: "정지",
    COMMAND_HOME: "대기 위치로 이동",
    COMMAND_CHARGE: "충전",
    COMMAND_MOVE: "이동",
    COMMAND_TTS: "문자 보내기",
    COMMAND_VIDEO: "동영상 보내기",
    COMMAND_REBOOT: "재부팅",
    COMMAND_TURN: "자세 제어",
    COMMAND_SOUND: "볼륨 조절",
    COMMAND_BACK_TO_SCHEDULE: "스케줄 복귀",
    COMMAND_PAUSE: "일시정지",
    COMMAND_REFRESH: "관리자 복귀",
    COMMAND_MACRO: "매크로",
    COMMAND_CLEAN: "청소시작",
    COMMAND_REPORT: "레포트",
    COMMAND_CONTENTS_PLAY: "콘텐츠 재생",
    COMMAND_DOCKING: "도킹",
    COMMAND_UNDOCKING: "언도킹",
    COMMAND_HALL_CALL: "홀콜",
    COMMAND_CAR_CALL: "카콜",
    COMMAND_OPEN_DOOR: "문열림",
    COMMAND_TASK: "태스크",
  };

  const getWorkName = (selectWork: any) => {
    if (selectWork.command === "COMMAND_MOVE") {
      const filteredList = cmdList
        ?.find((item) => item.command === "COMMAND_MOVE")
        ?.poiList?.find((poi: any) => poi.id === selectWork.poiId);
      return filteredList?.name ?? "";
    }
    if (selectWork.command === "COMMAND_CLEAN") {
      const filteredList = cmdList
        ?.find((item) => item.command === "COMMAND_CLEAN")
        ?.executableTasks?.find(
          (task: any) => task.taskId === selectWork.executableTask.taskId
        );
      return filteredList?.taskName ?? "";
    }
    if (selectWork.command === "COMMAND_TASK") {
      const filteredTask = cmdList
        ?.find((item) => item.command === "COMMAND_TASK")
        ?.taskList?.find((task: any) => task.taskId === selectWork.taskId);
      return filteredTask?.taskName ?? "";
    }
    return "";
  };

  const onSelectSite = (value: string) => {
    setSelectSite(value);
    setSelectRobot({});
    setSelectWork({});
    setWorkName("");
    selectedSiteRobotData(value);
    updateRobotCommand(null);
  };

  const onRepeatDay = (value: string[]) => {
    value.length === 7 ? setRepeatAll(true) : setRepeatAll(false);

    if (value.length > 0) {
      setRepeatDayValid("");
    }

    setRepeatDay(value);
  };
  /* 작업 일자 */
  const onWorkDate = (date: Dayjs, dateString: string | string[]) => {
    if (dateString) {
      setWorkDateValid("");
    }
    setWorkDate(date);
    setWorkDateStr(dateString);
  };
  /* 작업 시간 */
  const onWorkStartTime = (time: Dayjs, timeString: string | string[]) => {
    if (timeString) {
      setWorkStartTimeValid("");
    }
    setWorkStartTime(time);
    setWorkStartTimeStr(timeString);
  };

  /* 반복 설정 */
  const onUseRepeat = (checked: boolean) => {
    setUseRepeat(checked);

    if (checked) {
      setDisableStatus(false);
    } else {
      setDisableStatus(true);
      setRepeatDates([]);
      setRepeatAll(false);
      setRepeatDay([]);
    }
  };

  const onWorkEndDate = (date: Dayjs, dateString: string | string[]) => {
    if (dateString) {
      setWorkDateValid("");
    }
    setWorkEndDate(date);
    setWorkEndDateStr(dateString);
  };

  /* 반복 요일 - 매일 checkbox */
  const onRepeatAll = (e: CheckboxChangeEvent) => {
    e.target.checked
      ? setRepeatDay(optionsList.map((option) => option.value))
      : setRepeatDay([]);
    setRepeatAll(e.target.checked);
  };

  const checkValid = () => {
    const selectSiteError = selectSite === undefined || selectSite === null;
    const selectRobotError = Object.keys(selectRobot).length === 0;
    const selectWorkError = Object.keys(selectWork).length === 0;
    const workDateError = workDate === undefined || workDate === null;
    const workStartTimeError =
      workStartTime === undefined || workStartTime === null;
    const repeatDayError = useRepeat && repeatDay.length === 0;
    const workEndDateError =
      useRepeat && (workEndDate === undefined || workEndDate === null);
    const repeatDatesError = useRepeat && repeatDates.length === 0;

    setSelectSiteValid(selectSiteError ? "error" : "");
    setSelectRobotValid(selectRobotError ? "error" : "");
    setSelectWorkValid(selectWorkError ? "error" : "");
    setWorkDateValid(workDateError ? "error" : "");
    setWorkStartTimeValid(workStartTimeError ? "error" : "");
    setRepeatDayValid(repeatDayError || repeatDatesError ? "error" : "");
    setWorkEndDateValid(workEndDateError ? "error" : "");

    return [
      selectSiteError,
      selectRobotError,
      selectWorkError,
      workDateError,
      workStartTimeError,
      repeatDayError,
      workEndDateError,
      repeatDatesError,
    ].some((error) => error);
  };

  const makeScheduleData = () => ({
    siteId: selectSite,
    deviceId: selectRobot.deviceId,
    robotCommand: {
      deviceId: selectRobot.deviceId,
      command: selectWork.command,
      ...(selectWork.poiId && { poiId: selectWork.poiId }),
      ...(selectWork.taskId && { taskId: selectWork.taskId }),
      ...(selectWork.targetFloor && { targetFloor: selectWork.targetFloor }),
      ...(selectWork.startingFloor && {
        startingFloor: selectWork.startingFloor,
      }),
      ...(selectWork.cleaningMode && { cleaningMode: selectWork.cleaningMode }),
      ...(selectWork.executableTask && {
        executableTask: selectWork.executableTask,
      }),
    },
    startDate: workDateStr,
    startTime: workStartTimeStr,
    isRepeated: useRepeat,
    repeatedDays: repeatDay,
    repeatedDates: repeatDates,
    endDate: workEndDateStr,
  });

  const onScheduleSave = () => {
    const hasError = checkValid();
    if (hasError) return;

    const scheduleData = makeScheduleData();
    console.log(" ", scheduleData);
    saveScheduleData(scheduleData);
    onScheduleReset();
  };

  const onScheduleEdit = () => {
    const hasError = checkValid();

    if (hasError) return;

    const scheduleData = {
      scheduleId,
      ...makeScheduleData(),
    };
    editScheduleData(scheduleData);
    onScheduleReset();
  };

  const onScheduledelete = () => {
    removeScheduleData(scheduleId as string);
    onScheduleReset();
  };

  const onScheduleReset = () => {
    updateScheduleId(null);
    updateDeviceId(null);
    updateRobotCommand(null);

    setSelectSite(null);
    setRepeatAll(false);
    setRepeatDay([]);
    setUseRepeat(false);
    setSelectRobot({});
    setSelectWork({});
    setWorkName("");

    setSelectSiteValid("");
    setSelectRobotValid("");
    setSelectWorkValid("");
    setWorkDateValid("");
    setWorkStartTimeValid("");
    setRepeatDayValid("");
    setWorkEndDateValid("");

    ///날짜...
    setRepeatDates([]);
    setWorkDate(null);
    setWorkStartTime(null);
    setWorkEndDate(null);

    setWorkDateStr(null);
    setWorkStartTimeStr(null);
    setWorkEndDateStr("");
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <section
      className={
        "w-[35%] h-[700px] p-7 rounded-md mt-5 ml-5 bg-white shadow-sm"
      }
    >
      <div style={style.titleStyle}>
        <div className="overflow-hidden pb-[10px]">
          <GrSchedules size={30} className="float-left" />
          <span className="w-full" style={style.textStyle}>
            스케줄 설정
          </span>
        </div>

        <div className={"wrap-btn"}>
          <Button
            className={"buttonSwitchPrimary"}
            icon={<MdSaveAs />}
            onClick={onScheduleSave}
            type="primary"
            disabled={!!scheduleId}
          >
            저장
          </Button>
          <Button
            className={"buttonSwitchPrimary"}
            icon={<MdEdit />}
            onClick={onScheduleEdit}
            type="primary"
            disabled={!scheduleId}
          >
            수정
          </Button>
          <Button
            className={"buttonSwitchPrimary"}
            icon={<MdDeleteForever />}
            onClick={onScheduledelete}
            type="primary"
            disabled={!scheduleId}
          >
            삭제
          </Button>
          <Button
            className={"buttonSwitch"}
            icon={<GrPowerReset />}
            onClick={onScheduleReset}
          >
            리셋
          </Button>
        </div>
      </div>

      <div style={style.subTitleStyle}>
        <div style={style.alignCenter}>
          <BsDot size={20} />
        </div>
        <div style={style.alignCenter}>
          <span style={style.subTextStyle}>사이트</span>
        </div>
        <div style={{ width: "70%" }}>
          <Select
            showSearch
            style={{ width: "95%", marginLeft: "15px" }}
            placeholder="Please select"
            size={"middle"}
            onChange={onSelectSite}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={optionSiteList}
            status={selectSiteValid}
            value={selectSite}
          />
        </div>
      </div>

      <div style={style.subTitleStyle}>
        <div style={style.alignCenter}>
          <BsDot size={20} />
        </div>
        <div style={style.alignCenter}>
          <span style={style.subTextStyle}>작업 설정</span>
        </div>
        <div>
          <Input
            addonBefore={"로봇"}
            size={"middle"}
            style={{ width: "90%", marginLeft: "15px" }}
            readOnly
            onClick={showModal}
            value={selectRobot?.name}
            status={selectRobotValid}
          />
        </div>
        <div>
          <Input
            addonBefore={"업무"}
            size={"middle"}
            style={{ width: "90%" }}
            readOnly
            value={workName}
            onClick={showModal}
            status={selectWorkValid}
          />
        </div>
      </div>

      <div style={style.subTitleStyle}>
        <div style={style.alignCenter}>
          <BsDot size={20} />
        </div>
        <div style={style.alignCenter}>
          <span style={style.subTextStyle}>작업 일시</span>
        </div>
        <div>
          <DatePicker
            size={"middle"}
            style={{ marginLeft: "15px" }}
            onChange={(date, dateString) => {
              onWorkDate(date, dateString);
            }}
            status={workDateValid}
            value={workDate}
          />
        </div>
        <div>
          <TimePicker
            style={{ marginLeft: "10px" }}
            status={workStartTimeValid}
            onChange={(time, timeString) => {
              onWorkStartTime(time, timeString);
            }}
            value={workStartTime}
          />
        </div>
      </div>

      <div style={style.subTitleStyle}>
        <div style={style.alignCenter}>
          <BsDot size={20} />
        </div>
        <div style={style.alignCenter}>
          <span style={style.subTextStyle}>반복 설정</span>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Switch
            onChange={onUseRepeat}
            value={useRepeat}
            className={"scheduleSwitch"}
            style={{ marginLeft: "15px" }}
          />
        </div>
      </div>

      <div style={style.subTitleStyle}>
        <div style={style.alignCenter}>
          <BsDot size={20} />
        </div>
        <div style={style.alignCenter}>
          <span style={style.subTextStyle}>반복 요일</span>
        </div>
        <div style={{ width: "70%" }}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "95%", marginLeft: "15px" }}
            placeholder="Please select"
            size={"middle"}
            onChange={onRepeatDay}
            options={optionsList}
            value={repeatDay}
            disabled={disableStatus}
            status={repeatDayValid}
          />
        </div>
        <div style={{ alignSelf: "center", marginLeft: "5px" }}>
          <Checkbox
            checked={repeatAll}
            disabled={disableStatus}
            onChange={(e) => onRepeatAll(e)}
          >
            매일
          </Checkbox>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          height: "34px",
          marginTop: "25px",
        }}
      >
        <div style={style.alignCenter}>
          <BsDot size={20} />
        </div>
        <div style={style.alignCenter}>
          <span style={style.subTextStyle}>종료 일자</span>
        </div>

        <div>
          <DatePicker
            size={"middle"}
            style={{ width: "90%", marginLeft: "15px" }}
            disabled={disableStatus}
            status={workEndDateValid}
            onChange={(date, dateString) => {
              onWorkEndDate(date, dateString);
            }}
            value={workEndDate}
          />
        </div>
      </div>
      {/*}*/}

      {/* 로봇별 작업설정 모달 */}
      <SettingWorkModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalTitle={"작업설정"}
        useWork={true}
        setSelectRobot={setSelectRobot}
        setSelectWork={setSelectWork}
        robotFilterData={robotFilterData}
      />
    </section>
  );
};

export default SettingSchedule;
