import { useEffect, useState } from "react";

import { DeviceDataType, RobotType } from "../_types";
import { AuthType, Permission } from "../_types/user";
import { getAllRobotList, getRobotData } from "../_api/firestore/robot";
import useUserStore from "../store/userStore";
import {SOCKET_URL} from "../_api/webSocket";
import {io} from "socket.io-client";

export default function useRobotData(userData: AuthType | null) {
  const { userStatus } = useUserStore();

  const [socketData, setSocketData] = useState<DeviceDataType[]>([]);
  const [robotList, setRobotList] = useState<RobotType[]>([]);
  const [syncRobotList, setSyncRobotList] = useState<DeviceDataType[] | null>(
    null
  );

  useEffect(() => {
    const dataSocket = io(`${SOCKET_URL}/data`);

    dataSocket.on("connect", () => {});
    dataSocket.on("redisToClient", (data) => {
      setSocketData(data.devices);
    });

    return () => {
      dataSocket.disconnect();
      dataSocket.off('connect');
      dataSocket.off('redisToClient');
    }
  }, []);

  useEffect(() => {
    if (!userData) return;

    if (userStatus === Permission.SUPER_ADMIN) {
      getAllRobotList(setRobotList);
      return;
    }

    const fetchData = async () => {
      const data = await getRobotData(userData.auth_robots as []);

      setRobotList(data as RobotType[]);
    };

    if (userStatus === Permission.ENGINEER || userStatus === Permission.USER) {
      fetchData();
    }
  }, [userData, userStatus]);

  useEffect(() => {
    if (!robotList || !socketData) return;

    let tempTable: DeviceDataType[] = [];

    for (const robot of socketData) {
      const temp = robotList.find(
        (item: any) => item?.device_id === robot?.deviceId
      );
      temp &&
        tempTable.push({
          ...robot,
          siteId: temp.site_id,
          robotReference: temp.robot_reference,
        });
    }

    const filteredRobotList = robotList
      .filter(
        (robot) => !socketData.find((s) => s.deviceId === robot.device_id)
      )
      .map(
        (r) =>
          ({
            siteId: r.site_id,
            robotReference: r.robot_reference,
            deviceId: r.device_id,
            id: r.id as string,
            manufacturerEn: r.manufacturer,
            modelName: r.model_name,
            modelType: r.model_type,
            name: r.name,
          } as DeviceDataType)
      );

    tempTable = tempTable.concat(filteredRobotList);

    setSyncRobotList(tempTable);
  }, [socketData]);

  return { socketData, syncRobotList };
}
