import React from 'react'
import { RobotApiDownBtn, RobotApiSubTitle, RobotApiTitle, RobotApiTxtArea, RobotApiWrapper } from './styles';
import ImgSlider from '../../components/introduce/ImgSlider';
import { Icon } from '../../pages/IntroducePage';
import { CiShare1 } from "react-icons/ci";

export const RobotApi = () => {
  return (
    <RobotApiWrapper>
      <RobotApiTxtArea>
        <RobotApiTitle>세계 최다 연동 기술력 보유!</RobotApiTitle>
        <RobotApiSubTitle>
          국내·외 대표 브랜드와 로봇이 다수 연동되어 있으며<br />
          추가 연동 작업 중에 있습니다.
        </RobotApiSubTitle>
      </RobotApiTxtArea>
      <ImgSlider />
      <a href="https://bigwaverobotics.notion.site/SOLlink-Hub-474397c99ad946e6b675325da04c7520" target="_blank" rel="noopener noreferrer">
        <RobotApiDownBtn>
          Developers 바로가기
          <Icon><CiShare1 /></Icon>
        </RobotApiDownBtn>
      </a>
    </RobotApiWrapper>
  )
}

export default React.memo(RobotApi);