export enum FirestoreCollenctions {
  USERS = "usersClone",
  SITES = "sites",
  STANDARD_MAPS = "standard_map",
  ROBOT_MAPS = "robot_map",
  ROBOTS = "test_robot", // TODO: 컬렉션 이동후 네임변경
  ROBOT_DEVICES = "robot_devices",
  ROBOT_COMMAND = "robot_command",
  ROBOT_REFERENCE = "robot_reference",
  ROBOT_POI = "robot_poi",
  USER_AUTH = "user_auth",
}

export interface ReturnData {
  message: "string";
  statusCode: number;
}

export enum Pathname {
  MAIN = "/",
  TOTAL = "/total",
  VERIFICATION = "/verification",
  SIGNUP = "/signup",
  STREAMVIEW = "/streamview",
  DASHBOARD = "/dashboard",
  ROBOT = "/robot",
  USERPAGE = "/userpage",
  HISTORY = "/history",
  SCHEDULE = "/schedule",
  WORKFLOW = "/workflow",
  BOOKMARK = "/bookmark",
  NOTFOUND = "*",
}
