import core from '../../config/axios';

const { client, isClient } = core;

/* actinon 목록 */
export async function getBuilderNodeList(siteId: string, deviceId: string) {
  try {
    const res = await client.get(
      `robot/node-list/all?siteId=${siteId}&deviceId=${deviceId}`,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* task 목록 */
export async function getTaskNodeList(siteId: string, deviceId: string) {
  try {
    const res = await client.get(
      `/task/all?siteId=${siteId}&deviceId=${deviceId}`,
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* task 저장 */
export async function postTaskNode(data: object) {
  try {
    const res = await client.post('/task', data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

/* task id 검색*/
export async function getTaskNode(taskId: number) {
  try {
    const res = await client.get(`/task/one/${taskId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* task 수정 */
export async function putTaskNode(data: object) {
  try {
    const res = await client.put(`/task`, data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* task 삭제 */
export async function deleteTaskNode(taskId: number) {
  try {
    const res = await client.delete(`/task/${taskId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* userset 목록 (사이트별) */
export async function getUsersetNodeList(siteId: string) {
  try {
    const res = await client.get(`/task-userset/all/by-site?siteId=${siteId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* userset id 검색*/
export async function getUsersetNode(usersetId: number) {
  try {
    const res = await client.get(`/task-userset/one/${usersetId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* userset 저장 */
export async function postUsersetNode(data: object) {
  try {
    const res = await client.post('/task-userset', data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

/* userset 수정 */
export async function putUsersetNode(data: object) {
  try {
    const res = await client.put(`/task-userset`, data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* userset 삭제 */
export async function deleteUsersetNode(usersetId: number) {
  try {
    const res = await client.delete(`/task-userset/${usersetId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* preset 목록 (사이트별) */
export async function getPresetNodeList(siteId: string) {
  try {
    const res = await client.get(`/task-preset/all/by-site?siteId=${siteId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* preset id 검색*/
export async function getPresetNode(presetId: number) {
  try {
    const res = await client.get(`/task-preset/one/${presetId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* preset 저장 */
export async function postPresetNodeSave(data: object) {
  try {
    const res = await client.post('/task-preset', data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

/* preset 수정 */
export async function putPresetNode(data: object) {
  try {
    const res = await client.put(`/task-preset`, data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* preset 삭제 */
export async function deletePresetNode(presetId: number) {
  try {
    const res = await client.delete(`/task-preset/${presetId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* 워크플로우 start */
export async function startWorkflow(data: object) {
  try {
    const res = await isClient.post(`/task/command`, data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

/* 워크플로우 stop */
export async function stopWorkflowApi(data: object) {
  try {
    const res = await isClient.post(`/task/command`, data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getSiteRobotList(siteId: string) {
  try {
    const res = await client.get(`/robot/list/by-site?siteId=${siteId}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}
