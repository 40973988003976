import axios from "axios";

export async function changePassword(uid: string, password: string) {
  return axios.post(
    "https://asia-northeast3-sollink-f2425.cloudfunctions.net/api/auth/accounts/change-password",
    //"http://127.0.0.1:5001/sollink-f2425/asia-northeast3/api/auth/accounts/change-password",
    {
      uid,
      password,
    }
  );
}
