import { RobotCommandType } from "./robotCmd";

export enum TaskState {
  PENDING = "보류",
  ON_PROCESS = "진행 중",
  PAUSE = "일시 정지",
  ERROR = "에러 정지",
  STOP = "중지",
  USER_STOP = "사용자 중지",
  COMPLETE = "완료",
}

export interface AgentList {
  device_type: string;
  deviceId: string;
}

export interface CurrentTask {
  taskId: string;
  taskName: string;
  taskState: keyof typeof TaskState;
  prevAction: string;
  currentAction: string;
  currentActionAlias: string;
  currentActionCount: string | number;
  totalActionCount: string | number;
  startTime: string;
  lastUpdateTime: string;
  ellapsedTimeTotal: string;
  ellapsedTimeAction: string;
  isVisible: boolean;
  agentList?: string[];
  agentNameList: string[];
  prevActionAlias: string;
  taskMsg?: Record<string, never>;
}

export interface DetailTaskMessage {
  commandId: string;
  time: string;
  siteId: string;
  type: string;
  message: {
    message_type: string;
    command_name: string;
    device_type: string;
    deviceId: string;
    action: {
      command: string;
      param: { on_time: string; wait_time: string };
    };
    transition: [{ trigger: string; source: string; dest: string }];
  };
}
export interface TaskMessage {
  taskId: string;
  taskState: keyof typeof TaskState;
  prevAction: string;
  currentAction: string;
  totalActionCount: string;
  startTime: string;
  currentActionCount: string;
  lastUpdateTime: string;
  ellapsedTimeTotal: string;
  ellapsedTimeAction: string;
  agentList: AgentList[];
  taskMsg: Record<string, DetailTaskMessage>;
}

export interface DoneTaskList {
  id?: number;
  siteId?: string;
  taskId: string;
  time: string;
  message?: TaskMessage;
  operation?: string;
  taskState: keyof typeof TaskState;
  taskStateAlias: string;
}

export interface scheduleType {
  scheduleId?: number;
  siteId: string;
  deviceId: string;
  robotCommand: {
    deviceId: string;
    command: RobotCommandType;
  };
  startDate: string;
  startTime: string;
  isRepeated: boolean;
  repeatedDays: string[];
  repeatedDates: string[];
  endDate: string;
}
export interface ScheduleSocketData {
  siteId: string;
  currentTask: CurrentTask[];
}
