import { useCallback, useEffect, useState } from "react";
import "../../../style/animation.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import { DocumentReference } from "firebase/firestore";

import { CommonCommandType, DeviceDataType } from "../../../_types";
import JobOrderButton from "./JobOrderButton";
import dayjs from "dayjs";
import useRobotstore from "../../../store/robotStore";

import SplashBGPng from "../../../assets/image/image_map_splash_bg.png";
import SplashArrowPng from "../../../assets/image/image_map_splash_arrow.png";
import SplashTitleSvg from "../../../assets/image/image_map_splash_title.svg";
import { OperationVideo } from "../video/OperationVideo";
import useRobotData from "../../../hooks/useRobotData";
import useUserData from "../../../hooks/useUserData";
import core from "../../../config/axios";
import { t } from "i18next";
import { getCmdList } from "../../../_api/rest/getAPI";

export const PATH_UNITY_DETAIL = "/build_ver1.0";

type PropsType = {
  command?: DocumentReference;
};

export default function CommonDetail({ command }: PropsType) {
  const { selectedRobot, updateIsShowRobotDetail } = useRobotstore();

  // 디테일 페이지에 표시되는 로봇 정보 담는 객체, 웹소켓 데이터 타입 지정 필요
  const [robotDetailData, setRobotDetailData] = useState<any>(null);

  // 선택된 로봇의 디바이스 아이디 저장
  const [deviceId, setDeviceId] = useState<string>();
  const [cmdList, setCmdList] = useState<CommonCommandType[]>([]);

  const { unityProvider, isLoaded, sendMessage, unload } = useUnityContext({
    loaderUrl: PATH_UNITY_DETAIL + ".loader.js",
    dataUrl: PATH_UNITY_DETAIL + ".data",
    frameworkUrl: PATH_UNITY_DETAIL + ".framework.js",
    codeUrl: PATH_UNITY_DETAIL + ".wasm",
  });

  const [currentMap, setCurrentMap] = useState<any[]>([]);

  const [showSplash, setShowSplash] = useState(true);
  const [isInitMap, setIsInitMap] = useState(false);
  const { client } = core;
  const handleRobotPosition = useCallback(() => {
    // if (currentMap?.length && currentMap?.length > 0) {
    //   if (robotDetailData.standardLocation) {
    //     sendMessage(
    //       "Robot",
    //       "SetDestination",
    //       `${robotDetailData.standardLocation?.x},${robotDetailData.standardLocation?.y}`
    //     );
    //     setCurrentFloor(robotDetailData.standardLocation.floorIndex);
    //   }
    // }
  }, [currentMap?.length, robotDetailData?.standardLocation, sendMessage]);

  const { user } = useUserData();
  const { socketData } = useRobotData(user);

  useEffect(() => {
    if (socketData.length !== 0) {
      const tempSelectedRobot = socketData.find(
        (item: DeviceDataType) => item.deviceId === selectedRobot
      );
      setRobotDetailData(tempSelectedRobot);
    }
  }, [socketData, selectedRobot]);

  useEffect(() => {
    if (!isLoaded) return;
    handleRobotPosition();
  }, [handleRobotPosition, isLoaded]);

  useEffect(() => {
    if (robotDetailData) {
      setDeviceId(robotDetailData.deviceId);
    }

    if (isInitMap) {
      setIsInitMap(false);
    } else {
      getRobotMap();
    }
  }, [robotDetailData]);

  useEffect(() => {
    if (command && selectedRobot) {
      getCmdList(selectedRobot).then((res) => setCmdList(res as any));
    }
  }, [command, selectedRobot]);

  useEffect(() => {
    setShowSplash(true);
  }, [deviceId]);

  useEffect(() => {
    if (!isInitMap) {
      getRobotMap();
    }
  }, [isInitMap]);

  const getRobotMap = async () => {
    if (!robotDetailData) return;
    const result = await client.get(
      `/robot-building-floor-map/public-map-url?deviceId=${robotDetailData.deviceId}&buildingIdx=${robotDetailData.standardLocation.buildingIndex}&floorIdx=${robotDetailData.standardLocation.floorIndex}`
    );
    setCurrentMap(result.data);
  };

  useEffect(() => {
    if (!isInitMap && isLoaded && currentMap && currentMap.length > 0) {
      sendMessage(
        "MainManager",
        "GetFloorImage",
        JSON.stringify({
          url: currentMap[0].publicUrl,
          scale: currentMap[0].scale,
          centerPoint: currentMap[0].centerPoint ?? undefined,
          robotPosition: robotDetailData.standardLocation
            ? `${robotDetailData.standardLocation.x},${robotDetailData.standardLocation.y}`
            : undefined,
        })
      );
      setIsInitMap(true);
    }
  }, [currentMap, isLoaded, sendMessage]);
  const closeMenu = () => {
    updateIsShowRobotDetail(false);
  };
  return (
    <>
      {robotDetailData && (
        <>
          <div
            onClick={closeMenu}
            className="self-end text-black hover:cursor-pointer"
          >
            ✕
          </div>
          <div className="text-2xl">{t("Detailed_Information")}</div>
          <div className="mt-6 mb-7">
            <div className="mb-2 text-sm font-bold">
              {t("Basic_Information")}
            </div>
            {robotDetailData.modelType && (
              <div className=" text-center text-xs w-[98%]">
                <div className="flex items-center font-bold rounded-lg shadow-sm bg-neutral-200">
                  <div className="w-[120px] p-2">{t("Type")}</div>
                  <div className="w-[120px] p-2">{t("robot_name")}</div>
                  <div className="w-[120px] p-2">{t("Manufacturer")}</div>
                  <div className="w-[150px] p-2">{t("Model_Name")}</div>
                  <div className="w-[180px] p-2">{t("Waiting_Area")}</div>
                  <div className="w-[180px] p-2">{t("Charging_Station")}</div>
                </div>
                <div>
                  <div
                    key={robotDetailData.id}
                    className="flex items-center mt-2 bg-white rounded-lg shadow-sm"
                  >
                    <div className="w-[120px] p-2">
                      {robotDetailData.modelType ?? "-"}
                    </div>
                    <div className="w-[120px] p-2">
                      {robotDetailData.name ?? "-"}
                    </div>
                    <div className="w-[120px] p-2">
                      {robotDetailData.manufacturer ?? "-"}
                    </div>
                    <div className="w-[150px] p-2">
                      {robotDetailData.modelName ?? "-"}
                    </div>
                    <div className=" w-[180px] p-2">
                      {robotDetailData.modelType !== "방역로봇" &&
                        robotDetailData.modelType !== "청소로봇" && (
                          <span>{robotDetailData.homePoi ?? "-"}</span>
                        )}
                    </div>
                    {robotDetailData.modelType !== "방역로봇" && (
                      <div className="w-[180px] p-2">
                        {robotDetailData.chargePoi ?? "-"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-3 mb-7">
            <div className="mb-2 text-sm font-bold">{t("status")}</div>
            <div className="text-center text-xs w-[98%]">
              <div className="flex items-center font-bold rounded-lg shadow-sm bg-neutral-200">
                <div className="w-[50px] p-2">{t("status")}</div>
                {robotDetailData.modelType !== "홈케어로봇" && (
                  <div className="w-[120px] p-2">{t("Current_Task")}</div>
                )}
                {robotDetailData.modelType !== "홈케어로봇" &&
                  robotDetailData.modelType !== "방역로봇" && (
                    <div className="w-[120px] p-2">
                      {`${t("estimated_work_time")} ${t("second")}`}
                    </div>
                  )}
                {robotDetailData.modelType !== "홈케어로봇" && (
                  <div className="w-[100px] p-2">{t("Building_Name")}</div>
                )}
                <div className="w-[120px] p-2">{t("battery_level")} (%)</div>
                <div className="w-[120px] p-2">{t("Data_Update_Time")}</div>
              </div>
              <div className="flex items-center mt-2 bg-white rounded-lg shadow-sm">
                <div className="w-[50px] p-2">
                  {robotDetailData.robotStateColor === "RED" && (
                    <span className="text-red-500">●</span>
                  )}
                  {robotDetailData.robotStateColor === "GREEN" && (
                    <span className="text-emerald-500">●</span>
                  )}
                  {robotDetailData.robotStateColor === "ORANGE" && (
                    <span className="text-orange-400">●</span>
                  )}
                  {robotDetailData.robotStateColor === "GRAY" && (
                    <span className="text-gray-400">●</span>
                  )}
                </div>
                {robotDetailData.modelType !== "홈케어로봇" && (
                  <div className="w-[120px] p-2">
                    {robotDetailData.robotStateString !== ""
                      ? robotDetailData.robotStateString
                      : "-"}
                  </div>
                )}
                {robotDetailData.modelType !== "홈케어로봇" &&
                  robotDetailData.modelType !== "방역로봇" && (
                    <div className="w-[120px] p-2">
                      {robotDetailData.standardLocation &&
                      robotDetailData.standardLocation.remainExpectedTime
                        ? Math.floor(
                            Number(
                              robotDetailData.standardLocation
                                .remainExpectedTime
                            )
                          )
                        : "-"}
                    </div>
                  )}
                {/* <td>{robotState}</td> */}
                {robotDetailData.modelType !== "홈케어로봇" && (
                  <div className="p-2 w-[100px]">
                    {robotDetailData.standardLocation?.buildingIndexAlias !== ""
                      ? robotDetailData.standardLocation?.buildingIndexAlias
                      : "-"}
                  </div>
                )}
                <div className="w-[120px] p-2">
                  {robotDetailData.batteryLevel !== ""
                    ? robotDetailData.batteryLevel
                    : "-"}
                </div>
                <div className="w-[120px] p-2">
                  {robotDetailData.lastUpdateTime
                    ? dayjs(robotDetailData.lastUpdateTime).format(
                        "MM-DD HH:mm:ss"
                      )
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          {cmdList && cmdList.length > 0 && (
            <div className="mt-3 mb-7">
              <div className="pb-2 text-sm font-bold">{t("Work_Order")}</div>
              <JobOrderButton
                deviceId={robotDetailData.deviceId}
                isOnline={
                  robotDetailData.isOnline ||
                  !(robotDetailData.robotStateColor === "GRAY")
                }
                cmdList={cmdList.sort((a, b) => a.order - b.order)}
              />
            </div>
          )}
          <div className="pt-3 pb-7">
            <div className="robot-route-container">
              <div className="pb-2 text-sm font-bold">
                {t("Robots_Movement_Path")}
              </div>
              <div
                className={
                  !currentMap || currentMap?.length === 0 ? "no-map-url" : ""
                }
              >
                {
                  <div>
                    {currentMap && currentMap.length > 0 && showSplash && (
                      <div className="map-splash-container">
                        <div className="map-splash-inner">
                          <div>
                            <img
                              className="map-splash-bg"
                              src={SplashBGPng}
                              alt="map splash bg"
                            ></img>
                            <img
                              className="map-splash-arrow"
                              src={SplashArrowPng}
                              onAnimationEnd={() => {
                                setShowSplash(false);
                              }}
                              alt="map splash arrow"
                            />
                          </div>
                          <img
                            className="map-splash-title"
                            src={SplashTitleSvg}
                            alt="map splash title"
                          />
                        </div>
                      </div>
                    )}
                    {currentMap &&
                      currentMap?.length > 0 &&
                      unityProvider &&
                      selectedRobot && (
                        <Unity
                          unityProvider={unityProvider}
                          style={{ width: "100%" }}
                        />
                      )}
                  </div>
                }
              </div>
            </div>
          </div>
          {robotDetailData.modelType &&
            (robotDetailData.modelType === "안내로봇" ||
              robotDetailData.modelType === "홈케어로봇") &&
            (robotDetailData.modelName === "포미" ||
              robotDetailData.modelName === "다솜이") && (
              <div className="pt-3 pb-7">
                <div className="robot-route-container">
                  <div className="pb-2 text-sm font-bold">
                    {t("Video_Call")}
                  </div>
                  <div>
                    {selectedRobot && (
                      <OperationVideo
                        type={
                          robotDetailData.modelName === "포미"
                            ? "MINI"
                            : "DASOM"
                        }
                        deviceId={robotDetailData.deviceId}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
}
